import * as React from "react"
import { ButtonStyle } from "../../../backend/functions/contrast-check/interfaces"
import { Box } from "../../elements"
import { Normal } from "./Normal"
import { Hover } from "./Hover"
import { Focus } from "./Focus"

export const ButtonInstance: React.FC<{
  button: ButtonStyle
  showCheckerBackground: boolean
}> = ({
  button: {
    normal,
    hover,
    focus,
    tagName,
    hasFocusVisible,
    hoverAndNormalAreTheSame,
  },
  showCheckerBackground,
}) => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" width={1}>
      <Box width={[1, 1, 1 / 3]} display="flex" flexDirection="column">
        <Normal
          normal={normal}
          hover={hover}
          focus={focus}
          tagName={tagName}
          showCheckerBackground={showCheckerBackground}
        />
      </Box>
      <Box width={[1, 1, 1 / 3]} display="flex" flexDirection="column">
        <Box mt={[8, 8, 0]} />
        <Hover
          normal={normal}
          hover={hover}
          tagName={tagName}
          showCheckerBackground={showCheckerBackground}
          hoverAndNormalAreTheSame={hoverAndNormalAreTheSame}
        />
      </Box>
      <Box width={[1, 1, 1 / 3]} display="flex" flexDirection="column">
        <Box mt={[8, 8, 0]} />
        <Focus
          normal={normal}
          focus={focus}
          tagName={tagName}
          showCheckerBackground={showCheckerBackground}
          hasFocusVisible={hasFocusVisible}
        />
      </Box>
    </Box>
  )
}
