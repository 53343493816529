import * as React from "react"
import * as deepMerge from "deepmerge"
import { ButtonState } from "../../../backend/functions/contrast-check/interfaces"
import { Box, Label, Text, LinkAnchor } from "../../elements"
import {
  IconTextBackgroundColor,
  IconObjectBackgroundColor,
  InfoTextContrast,
} from "../../components"
import { Colors } from "../../materials"
import { CHECKER_BG_PROPS } from "./checker-background"
export const Focus: React.FC<{
  normal: ButtonState
  focus?: ButtonState
  tagName: string
  hasFocusVisible: boolean
  showCheckerBackground: boolean
}> = ({ normal, focus, tagName, hasFocusVisible, showCheckerBackground }) => (
  <>
    <Box display="flex" justifyContent="center">
      <Label
        as="span"
        textAlign="center"
        css={{ display: "block", position: "relative" }}
      >
        Focus
        {hasFocusVisible && (focus && !focus.data.hasCustomFocus) && (
          <Label
            as="sup"
            color={Colors.oc.blue[9]}
            css={{ position: "absolute", top: -4, left: "100%" }}
          >
            *
          </Label>
        )}
        {!hasFocusVisible ? (
          <Label
            as="sup"
            color={Colors.oc.blue[9]}
            css={{ position: "absolute", top: -4, left: "100%" }}
          >
            *
          </Label>
        ) : null}
      </Label>
    </Box>
    <Box mb={3} />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={2}
      py={6}
      borderLeft={
        (focus || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderRight={
        (focus || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderTop={
        (focus || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderBottom={
        (focus || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      css={{
        ...(showCheckerBackground
          ? (normal || focus).data.wrapperHasBgImage
            ? { background: (normal || focus).data.wrapperBgStyle }
            : CHECKER_BG_PROPS
          : {
              background: (normal || focus).data.wrapperHasBgImage
                ? (focus || normal).data.wrapperBgStyle
                : (focus || normal).data.extrapolatedColors.wrapperBackgrounds
                    .length === 1
                ? (focus || normal).data.extrapolatedColors
                    .wrapperBackgrounds[0]
                : (focus || normal).data.wrapperBgStyle,
            }),
        overflow: "hidden",
      }}
    >
      {focus ? (
        <Box
          as={tagName}
          onClick={event => event.preventDefault()}
          alignSelf="center"
          css={
            hasFocusVisible
              ? {
                  ...deepMerge(
                    normal.data.style.computedStyle,
                    focus &&
                      focus.data &&
                      focus.data.style &&
                      focus.data.style.computedStyle
                      ? {
                          ...focus.data.style.computedStyle,
                          ...(focus.data.hasCustomFocus
                            ? {}
                            : {
                                outline:
                                  "rgba(0,103,244,0.247) auto 5px !important",
                              }),
                        }
                      : {}
                  ),
                }
              : normal.data.style.computedStyle
          }
          dangerouslySetInnerHTML={{ __html: normal.data.html }}
        />
      ) : (
        "N/A"
      )}
    </Box>
    {/* <pre>{JSON.stringify(normal.data.style.computedStyle, null, 2)}</pre> */}
    {/* <pre>{JSON.stringify(hasFocusVisible, null, 2)}</pre>
    <pre>{JSON.stringify(focus.data.style, null, 2)}</pre> */}
    {focus ? (
      <>
        <Box
          mt={4}
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="center"
          px={[3, 6]}
        >
          {focus.contrast.outer ? (
            <>
              {focus.contrast.inner.map((inner, index) => (
                <Box p={4} key={index}>
                  <IconTextBackgroundColor
                    color={inner.color2}
                    backgroundColor={inner.color1}
                    contrastScore={inner}
                  />
                </Box>
              ))}
              {hasFocusVisible
                ? focus.contrast.outer.map((outer, index) => (
                    <Box p={4} key={index}>
                      <IconObjectBackgroundColor
                        objectBackgroundColor={outer.color2}
                        containerBackgroundColor={outer.color1}
                        contrastScore={outer}
                        showWarning={!focus.data.hasCustomFocus}
                      />
                    </Box>
                  ))
                : normal.contrast.outer &&
                  normal.contrast.outer.map((outer, index) => (
                    <Box p={4} key={index}>
                      <IconObjectBackgroundColor
                        objectBackgroundColor={outer.color2}
                        containerBackgroundColor={outer.color1}
                        contrastScore={outer}
                        showWarning={!focus.data.hasCustomFocus}
                      />
                    </Box>
                  ))}
            </>
          ) : (
            focus.contrast.inner.map((inner, index) => (
              <Box p={4} key={index}>
                <IconTextBackgroundColor
                  color={inner.color2}
                  backgroundColor={inner.color1}
                  contrastScore={inner}
                />
              </Box>
            ))
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <InfoTextContrast
            contrast={hasFocusVisible ? focus.contrast : normal.contrast}
            showWarning={!focus.data.hasCustomFocus}
            state="FOCUS"
            buttonData={{
              normal,
              focus,
              tagName,
              showCheckerBackground,
            }}
          />
        </Box>

        {!hasFocusVisible ? (
          <Box
            maxWidth="copy"
            mx="auto"
            mt={[4]}
            display="flex"
            justifyContent="center"
          >
            <Text fontSize={2} color={Colors.oc.blue[9]}>
              <sup>*</sup>
              There are no visible focus styles!
            </Text>
          </Box>
        ) : null}

        {hasFocusVisible && (focus && !focus.data.hasCustomFocus) && (
          <Box
            maxWidth="copy"
            mx="auto"
            mt={[4]}
            display="flex"
            justifyContent="center"
          >
            <Text fontSize={2} color={Colors.oc.blue[9]}>
              <sup>*</sup>
              On Chrome (not visible on mobile devices)
            </Text>
          </Box>
        )}
      </>
    ) : null}
  </>
)
