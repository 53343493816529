import { Nullable } from "../../utils/types/Nullable"
import { ChecksColorsButtonsItem } from "../../../backend/functions/contrast-check/interfaces"

export enum Screen {
  Form = "Form",
  LoadingForm = "LoadingForm",
  LoadingFormPreShow = "LoadingFormPreShow",
  LoadingSlug = "LoadingSlug",
  Results = "Results",
  ErrorForm = "ErrorForm",
  ErrorSlug = "ErrorSlug",
}

export enum ActionType {
  GoToForm = "GoToForm",
  ShowPreResults = "ShowPreResults",
  GenerateResults = "GenerateResults",
  FetchResults = "FetchResults",
  ResultsReceived = "ResultsReceived",
  ErrorForm = "ErrorForm",
  ErrorSlug = "ErrorSlug",
  ErrorSlugNotFound = "ErrorSlugNotFound",
}

export interface ActionAnalizeUrl {
  type: ActionType.GenerateResults
  payload: {
    url: string
  }
}

export interface ActionFetchResults {
  type: ActionType.FetchResults
  payload: {
    slug: string
  }
}

export interface ActionGoToForm {
  type: ActionType.GoToForm
}

export interface ActionErrorForm {
  type: ActionType.ErrorForm
  payload: {
    status: number
  }
}

export interface ActionErrorSlug {
  type: ActionType.ErrorSlug
  payload: {
    status: number
  }
}

export interface ActionShowPreResults {
  type: ActionType.ShowPreResults
}

export interface ActionResultsReceived {
  type: ActionType.ResultsReceived
  payload: {
    audit: ChecksColorsButtonsItem
  }
}

export type Action =
  | ActionAnalizeUrl
  | ActionShowPreResults
  | ActionResultsReceived
  | ActionGoToForm
  | ActionErrorForm
  | ActionErrorSlug
  | ActionFetchResults

export interface StateForm {
  screen: Screen.Form
}
export interface StateWithAudit {
  screen: Screen.Results
  audit: ChecksColorsButtonsItem
}
export interface StateLoadingForm {
  screen: Screen.LoadingForm | Screen.LoadingFormPreShow
  url: string
  date: Date
}
export interface StateFetchingResults {
  screen: Screen.LoadingSlug
  slug: string
}

export interface StateErrorForm {
  screen: Screen.ErrorForm
  url: string
  date: Date
  errorStatus: number
}

export interface StateErrorSlug {
  screen: Screen.ErrorSlug
  slug: string
  errorStatus: number
}

export type State =
  | StateForm
  | StateWithAudit
  | StateLoadingForm
  | StateFetchingResults
  | StateErrorForm
  | StateErrorSlug
