import {
  State,
  StateLoadingForm,
  Screen,
  Action,
  ActionType,
  StateFetchingResults,
  ActionFetchResults,
} from "./interfaces"
import { navigate } from "@reach/router"

export const reducer: React.Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.GoToForm:
      navigate("/button-contrast-checker/")
      return {
        ...state,
        screen: Screen.Form,
      }
    case ActionType.ShowPreResults:
      if (state.screen === Screen.LoadingForm) {
        return {
          ...(state as StateLoadingForm),
          screen: Screen.LoadingFormPreShow,
        }
      }
      return state

    case ActionType.GenerateResults:
      return {
        screen: Screen.LoadingForm,
        url: action.payload.url,
        date: new Date(),
      }
    case ActionType.FetchResults:
      return {
        screen: Screen.LoadingSlug,
        slug: (action as ActionFetchResults).payload.slug,
      }
    case ActionType.ResultsReceived:
      return {
        screen: Screen.Results,
        audit: action.payload.audit,
      }
    case ActionType.ErrorForm:
      return {
        screen: Screen.ErrorForm,
        url: (state as StateLoadingForm).url,
        date: (state as StateLoadingForm).date,
        errorStatus: action.payload.status,
      }
    case ActionType.ErrorSlug:
      return {
        screen: Screen.ErrorSlug,
        slug: (state as StateFetchingResults).slug,
        errorStatus: action.payload.status,
      }
    default:
      throw new Error()
  }
}
