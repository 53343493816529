import * as React from "react"
import { Global, css } from "@emotion/core"
import { MatchRenderProps } from "@reach/router"
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from "../../elements"
import { Colors, Icons } from "../../materials"
import { State, Action, Screen } from "./interfaces"
import { TabSummary } from "./TabSummary"
import { TabFail } from "./TabFail"
import { TabPass } from "./TabPass"
import { Theory } from "../contrast-and-accessibility/Theory"
import { SectionSubscriptionMinimal } from "../../partials/SectionSubscriptionMinimal"

const tabs = ["overview", "failing", "success", "info"]

export const AuditResults: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
  routeProps: MatchRenderProps<{}>
}> = function({ state, routeProps }) {
  const index = tabs.indexOf(routeProps.location.search.substr(1))
  const onTabsChange = index =>
    routeProps.navigate(`${routeProps.location.pathname}?${tabs[index]}`, {
      replace: false,
    })
  const tabIndex = index === -1 ? 0 : index
  return (
    <>
      <Box bg={Colors.oc.gray[0]} id="AuditResults">
        <Tabs index={tabIndex} onChange={onTabsChange} id="Tabs">
          <TabList>
            <Tab as="a" href={`${routeProps.location.pathname}?${tabs[0]}`}>
              <Box
                width={[1, 1, "auto"]}
                height={[42, 42, "auto"]}
                display="flex"
                flexDirection={["column", "column", "row"]}
                alignItems="center"
                justifyContent={[
                  "space-between",
                  "space-between",
                  "flex-start",
                ]}
              >
                <Box
                  width={18}
                  display="flex"
                  alignItems="center"
                  mr={[0, 0, 2]}
                  mb={[1, 1, 0]}
                  css={{
                    svg: { width: "100%", height: "auto", strokeWidth: 1.5 },
                  }}
                  aria-hidden="true"
                >
                  <Icons.SvgDashboardStroke />
                </Box>
                <Box as="span" height={[20, "auto"]}>
                  Overview
                </Box>
              </Box>
            </Tab>
            <Tab as="a" href={`${routeProps.location.pathname}?${tabs[1]}`}>
              <Box
                width={[1, 1, "auto"]}
                height={[42, 42, "auto"]}
                display="flex"
                flexDirection={["column", "column", "row"]}
                alignItems="center"
                justifyContent={[
                  "space-between",
                  "space-between",
                  "flex-start",
                ]}
              >
                <Box
                  width={[18, 18, 20]}
                  display="flex"
                  alignItems="center"
                  mr={[0, 0, 2]}
                  mb={[1, 1, 0]}
                  css={{
                    svg: { width: "100%", height: "auto", strokeWidth: 1.5 },
                  }}
                  aria-hidden="true"
                >
                  <Icons.SvgVideoGamePacmanEnemy />
                </Box>
                <Box as="span" height={[20, "auto"]}>
                  Failing
                  <Box as="span" display={["none", "none", "inline"]}>
                    {" "}
                    Contrast
                  </Box>
                </Box>
              </Box>
            </Tab>
            <Tab as="a" href={`${routeProps.location.pathname}?${tabs[2]}`}>
              <Box
                width={[1, 1, "auto"]}
                height={[42, 42, "auto"]}
                display="flex"
                flexDirection={["column", "column", "row"]}
                alignItems="center"
                justifyContent={[
                  "space-between",
                  "space-between",
                  "flex-start",
                ]}
              >
                <Box
                  width={18}
                  display="flex"
                  alignItems="center"
                  mr={[0, 0, 2]}
                  mb={[1, 1, 0]}
                  css={{
                    svg: { width: "100%", height: "auto", strokeWidth: 2 },
                  }}
                  aria-hidden="true"
                >
                  <Icons.SvgLoveIt />
                </Box>
                <Box as="span" height={[20, "auto"]}>
                  Sufficient
                  <Box as="span" display={["none", "none", "inline"]}>
                    {" "}
                    Contrast
                  </Box>
                </Box>
              </Box>
            </Tab>
            <Tab as="a" href={`${routeProps.location.pathname}?${tabs[3]}`}>
              <Box
                width={[1, 1, "auto"]}
                height={[42, 42, "auto"]}
                display="flex"
                flexDirection={["column", "column", "row"]}
                alignItems="center"
                justifyContent={[
                  "space-between",
                  "space-between",
                  "flex-start",
                ]}
              >
                <Box
                  width={[18, 18, 22]}
                  display="flex"
                  alignItems="center"
                  mr={[0, 0, 1]}
                  mb={[1, 1, 0]}
                  aria-hidden="true"
                  css={{
                    svg: { width: "100%", height: "auto", strokeWidth: 2 },
                  }}
                >
                  <Icons.SvgBulbShine />
                </Box>
                <Box as="span" height={[20, "auto"]}>
                  Guidelines
                </Box>
              </Box>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {tabIndex === 0 && (
                <TabSummary state={state} routeProps={routeProps} />
              )}

              <Box mt={[8, 8, 10]} />
            </TabPanel>
            <TabPanel>
              {tabIndex === 1 && (
                <TabFail state={state} routeProps={routeProps} />
              )}
            </TabPanel>
            <TabPanel>
              {tabIndex === 2 && (
                <TabPass state={state} routeProps={routeProps} />
              )}
            </TabPanel>
            <TabPanel>
              {tabIndex === 3 && (
                <>
                  <Box mt={[6, 9]} />
                  <Theory />
                  <Box mt={[7, 11]} />
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        {state.screen === Screen.Results ? (
          <>
            <Global
              styles={css`
                ${state.audit.ExtraCss}
              `}
            />
            {/* <pre>{JSON.stringify({ html: state.audit.ExtraHtml }, null, 2)}</pre> */}
            <Box
              dangerouslySetInnerHTML={{ __html: state.audit.ExtraHtml }}
              display="none"
            />
          </>
        ) : null}
      </Box>
      <Box mt={[0, 0, 12]} />
      <SectionSubscriptionMinimal />
    </>
  )
}
