import * as React from "react"
import { ButtonStyle } from "../../../backend/functions/contrast-check/interfaces"
import { Box, Label, TextBold, List, ListItem } from "../../elements"
import { Colors } from "../../materials"
import { ButtonInstance } from "./ButtonInstance"

export const ButtonInstanceList: React.FC<{
  ids: string[]
  instances: Record<string, ButtonStyle>
  showCheckerBackground?: boolean
}> = ({ ids, instances, showCheckerBackground = false }) => (
  <List>
    {ids.map((id, index) => (
      <ListItem key={id}>
        {index > 0 ? <Box height={1} bg={Colors.oc.gray[4]} /> : null}

        <Box mt={4} />
        <Label fontSize={4} as="h5" color={Colors.oc.gray[6]}>
          Element #{index + 1}
        </Label>
        {instances[id].duplicates > 1 ? (
          <TextBold fontSize={1} color={Colors.oc.gray[7]}>
            {instances[id].duplicates} duplicated elements
          </TextBold>
        ) : null}

        <Box mt={7} />
        <ButtonInstance
          button={instances[id]}
          showCheckerBackground={showCheckerBackground}
        />
        <Box mt={[8, 10]} />
      </ListItem>
    ))}
  </List>
)
