import * as React from "react"
import * as deepMerge from "deepmerge"
import { ButtonState } from "../../../backend/functions/contrast-check/interfaces"
import { Box, Label } from "../../elements"
import { Colors } from "../../materials"
import {
  IconTextBackgroundColor,
  IconObjectBackgroundColor,
  InfoTextContrast,
} from "../../components"
import { CHECKER_BG_PROPS } from "./checker-background"

export const Normal: React.FC<{
  normal: ButtonState
  hover?: ButtonState
  focus?: ButtonState
  tagName: string
  showCheckerBackground: boolean
}> = ({ normal, hover, focus, tagName, showCheckerBackground }) => (
  <>
    <Label as="span" textAlign="center" css={{ display: "block" }}>
      Default
    </Label>
    <Box mb={3} />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={2}
      py={6}
      borderLeft={
        normal.data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !== -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderRight={
        normal.data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !== -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderTop={
        normal.data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !== -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderBottom={
        normal.data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !== -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      css={{
        ...(showCheckerBackground
          ? normal.data.wrapperHasBgImage
            ? { background: normal.data.wrapperBgStyle }
            : CHECKER_BG_PROPS
          : {
              background: normal.data.wrapperHasBgImage
                ? normal.data.wrapperBgStyle
                : normal.data.extrapolatedColors.wrapperBackgrounds.length === 1
                ? normal.data.extrapolatedColors.wrapperBackgrounds[0]
                : normal.data.wrapperBgStyle,
            }),

        overflow: "hidden",
      }}
    >
      <Box
        as={tagName}
        onClick={event => event.preventDefault()}
        alignSelf="center"
        css={{
          ...normal.data.style.computedStyle,
          "&:hover":
            hover &&
            hover.data &&
            hover.data.style &&
            hover.data.style.computedStyle
              ? hover.data.style.computedStyle
              : {},
          "&:focus":
            focus &&
            focus.data &&
            focus.data.style &&
            focus.data.style.computedStyle
              ? focus.data.style.computedStyle
              : {},
        }}
        dangerouslySetInnerHTML={{ __html: normal.data.html }}
      />
    </Box>
    {/* <pre>{JSON.stringify({ showCheckerBackground, wrapperHasBgImage: normal.data.wrapperHasBgImage })}</pre> */}
    <Box
      mt={4}
      display="flex"
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="center"
      px={[3, 6]}
    >
      {normal.contrast.outer ? (
        <>
          {normal.contrast.inner.map((inner, index) => (
            <Box key={index} p={4}>
              <IconTextBackgroundColor
                color={inner.color2}
                backgroundColor={inner.color1}
                contrastScore={inner}
              />
            </Box>
          ))}

          {normal.contrast.outer.map((outer, index) => (
            <Box p={4} key={index}>
              <IconObjectBackgroundColor
                objectBackgroundColor={outer.color2}
                containerBackgroundColor={outer.color1}
                contrastScore={outer}
              />
            </Box>
          ))}
        </>
      ) : (
        normal.contrast.inner.map((inner, index) => (
          <Box p={4} key={index}>
            <IconTextBackgroundColor
              color={inner.color2}
              backgroundColor={inner.color1}
              contrastScore={inner}
            />
          </Box>
        ))
      )}
    </Box>
    <Box display="flex" justifyContent="center">
      <InfoTextContrast
        contrast={normal.contrast}
        showWarning={false}
        state="DEFAULT"
        buttonData={{
          normal,
          hover,
          focus,
          tagName,
          showCheckerBackground,
        }}
      />
    </Box>
  </>
)
