import * as React from "react"
import styled from "@emotion/styled"

import { Box, Container, Heading, Text } from "../../elements"
import { Colors } from "../../materials"
import { SubscriptionForm } from "../SubscriptionForm"
import SuperheroMinimal from "../../../static/illustrations/home/superhero-minimal.inline.svg"

const Splitter = styled("div")(() => ({
  background: `linear-gradient(
    transparent 0%,
    transparent 20%,
    ${Colors.oc.blue[1]} 20%,
    ${Colors.oc.blue[1]} 100%
  )`,
}))

export const SectionSubscriptionMinimal: React.FC<{}> = ({}) => (
  <Splitter>
    <Container
      pb={8}
      pl={[3, 5, 6]}
      pr={[3, 5, 6]}
      display="flex"
      justifyContent="center"
    >
      <Box
        bg={Colors.oc.gray[0]}
        border={`2px solid ${Colors.oc.gray[5]}`}
        color={Colors.oc.blue[9]}
        borderRadius={10}
        px={[3, 3, 6]}
        py={[4, 4, 6]}
        css={{ position: "relative" }}
        display="flex"
        width={1}
      >
        <Box mb={[0, 3]} ml={[0, 4]} pr={[0, 4, 13]}>
          <Heading fontSize={[5, 6, 7]} pr={8}>
            A new a11y tool is coming...
          </Heading>
          <Box maxWidth="copy">
            <Box mt={2} />
            <Text fontSize={[2, 3]}>
              We&#39;re building the&nbsp;
              <strong>next-generation accessibility tool</strong>, and this
              can&#39;t be done without you. We&#39;re looking for accessibility
              superheroes.
            </Text>
            <Text mt={4} fontSize={[2, 3]}>
              To be notified when we launch, drop your email below.
            </Text>
          </Box>
          <Box mt={6}>
            <Box maxWidth="70ch">
              <SubscriptionForm />

              <span className="visually-hidden" id="email-description">
                Leave your email and we&#39;ll get in touch when we launch. You
                won&#39;t get SPAMed.
              </span>
            </Box>
          </Box>
        </Box>
        <Box
          display={["none", "none", "block"]}
          width={300}
          css={{
            position: "absolute",
            bottom: 0,
            right: 20,
            svg: { width: "100%", height: "auto" },
          }}
        >
          <SuperheroMinimal />
        </Box>
      </Box>
    </Container>
  </Splitter>
)
