import * as React from "react"
import { MatchRenderProps } from "@reach/router"
import { State, Screen } from "./interfaces"
import { Colors, Icons } from "../../materials"
import { ContrastResultExplanation } from "./ContrastResultExplanation"
import ContentLoader from "react-content-loader"
import {
  LinkAnchor,
  LinkArrowButton,
  LinkArrowTheme,
  LinkArrowGatsby,
  SubHeading,
  List,
  ListItem,
  Box,
  Text,
  Lead,
  DashboardCard,
  DashboardCardTheme,
  TextBold,
  LinkButton,
  Heading,
  LinkGatsby,
  ButtonGatsby,
  ButtonTheme,
} from "../../elements"
import { SvgDonut, IconText, IconContrast } from "../../components"
import IllustrationScanColors from "../../../static/illustrations/button-contrast-checker/scan-colors.inline.svg"

const LoadingText: React.FC<{
  widths: number[]
  height: number
  spacing: number
}> = ({ widths, height, spacing }) => {
  let cumulativeY = 0
  const maxWidth = widths.reduce((acc, cur) => Math.max(acc, cur), 0)
  const totalHeight = widths.length * height + (widths.length - 1) * spacing
  return (
    <ContentLoader
      height={totalHeight}
      width={maxWidth}
      speed={2}
      primaryColor={Colors.oc.gray[2]}
      secondaryColor={Colors.oc.gray[0]}
    >
      {widths.map((width, index) => {
        const currentY = cumulativeY
        cumulativeY = +height + spacing
        return (
          <rect
            key={index}
            x="0"
            y={currentY}
            rx="5"
            ry="5"
            width={width}
            height={height}
          />
        )
      })}
    </ContentLoader>
  )
}

const LoadingLead: React.FC<{ widths: number[] }> = ({ widths }) => (
  <LoadingText widths={widths} height={22} spacing={1.5 * 22 - 22} />
)

const ChartLabels: React.FC<{
  state: State
  routeProps: MatchRenderProps<{}>
}> = ({ state, routeProps }) => {
  const passAA =
    state.screen == Screen.Results
      ? state.audit.Result.buttonsByLevel.AA.length
      : 0
  const passAAA =
    state.screen == Screen.Results
      ? state.audit.Result.buttonsByLevel.AAA.length
      : 0
  const fail =
    state.screen == Screen.Results
      ? state.audit.Result.buttonsByLevel.fail.any.length
      : 0
  return (
    <>
      <List maxWidth={[250]} width={1}>
        <ListItem display="flex" alignItems="center">
          <Box
            mr={2}
            width={40}
            height={16}
            bg={
              state.screen === Screen.Results
                ? Colors.tw.green["800"]
                : Colors.oc.gray[4]
            }
          />
          <TextBold
            fontSize={4}
            css={{ textTransform: "uppercase" }}
            color={Colors.oc.gray[8]}
          >
            AAA
          </TextBold>
          <Box ml={3} mr="auto" />
          {passAAA === 0 ? (
            <TextBold mr={20}>
              0 <span className="visually-hidden">AAA</span> Elements
            </TextBold>
          ) : (
            <LinkArrowGatsby to={`${routeProps.location.pathname}?success#AAA`}>
              {passAAA} <span className="visually-hidden">AAA</span>&nbsp;
              {passAAA > 1 ? "Elements" : "Element"}
            </LinkArrowGatsby>
          )}
        </ListItem>
        <ListItem display="flex" alignItems="center" mt={2}>
          <Box
            mr={2}
            width={40}
            height={16}
            bg={
              state.screen === Screen.Results
                ? Colors.tw.green["500"]
                : Colors.oc.gray[4]
            }
          />
          <TextBold
            fontSize={4}
            css={{ textTransform: "uppercase" }}
            color={Colors.oc.gray[8]}
          >
            AA
          </TextBold>
          <Box ml={3} mr="auto" />
          {passAA === 0 ? (
            <TextBold mr={20}>
              0 <span className="visually-hidden">AA</span> Elements
            </TextBold>
          ) : (
            <LinkArrowGatsby to={`${routeProps.location.pathname}?success#AA`}>
              {passAA} <span className="visually-hidden">AA</span>&nbsp;
              {passAA > 1 ? "Elements" : "Element"}
            </LinkArrowGatsby>
          )}
        </ListItem>
        <ListItem display="flex" alignItems="center" mt={2}>
          <Box
            mr={2}
            width={40}
            height={16}
            bg={
              state.screen === Screen.Results
                ? Colors.tw.red["700"]
                : Colors.oc.gray[4]
            }
          />
          <TextBold
            fontSize={4}
            css={{ textTransform: "uppercase" }}
            color={Colors.oc.gray[8]}
          >
            Fail
          </TextBold>
          <Box ml={3} mr="auto" />
          {fail === 0 ? (
            <TextBold mr={20}>
              0 <span className="visually-hidden">failing</span> Elements
            </TextBold>
          ) : (
            <LinkArrowGatsby to={`${routeProps.location.pathname}?failing`}>
              {fail} <span className="visually-hidden">failing</span>&nbsp;
              {fail > 1 ? "Elements" : "Element"}
            </LinkArrowGatsby>
          )}
        </ListItem>
      </List>
      <Box mt={4} />
      <Text css={{ display: "flex" }}>
        <Text as="sup" css={{ marginRight: 4, fontSize: "smaller" }}>
          *
        </Text>
        <Text as="span" css={{ flex: "1 1 auto", maxWidth: "40ch" }}>
          duplicated elements are not shown.
          {state.audit && state.audit.Result.didTimeout ? (
            <>
              <br />
              <strong>We couldn't analyse all buttons</strong> because it was
              taking too long.
            </>
          ) : null}
        </Text>
      </Text>
    </>
  )
}

export const TabSummary: React.FC<{
  state: State
  routeProps: MatchRenderProps<{}>
}> = ({ state, routeProps }) => {
  const total =
    state.screen == Screen.Results
      ? Object.keys(state.audit.Result.instances).length
      : 0
  const passAA =
    state.screen == Screen.Results
      ? state.audit.Result.buttonsByLevel.AA.length
      : 0
  const passAAA =
    state.screen == Screen.Results
      ? state.audit.Result.buttonsByLevel.AAA.length
      : 0
  const pass = state.screen == Screen.Results ? passAA + passAAA : 0
  const fail =
    state.screen == Screen.Results
      ? state.audit.Result.buttonsByLevel.fail.any.length
      : 0
  return (
    <>
      {/* {state.audit && (
        <pre
          style={{
            zIndex: 999,
            position: "fixed",
            top: 0,
            right: 0,
            background: "red",
            color: "white",
            padding: "1rem",
            maxWidth: "50vw",
          }}
        >
          {JSON.stringify(state.audit!.Result.mistakesDueToPositioned, null, 2)}
        </pre>
      )} */}
      <Box pt={[6, 9]} />
      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        <Box width={[1, 1, 1, 2 / 3]}>
          <Box display="flex" flexWrap={["wrap", "nowrap"]}>
            {/* BOX LEFT */}
            <Box
              flex="0 0 auto"
              width={[1, "auto"]}
              display="flex"
              flexDirection="column"
            >
              <Box display="flex" alignItems="center">
                <Box
                  color={Colors.oc.gray[8]}
                  width={[24, 30]}
                  display="flex"
                  alignItems="center"
                  mr={[2, 3]}
                  css={{
                    svg: { width: "100%", height: "auto", strokeWidth: 1.5 },
                  }}
                  aria-hidden="true"
                >
                  <Icons.SvgDashboardStroke />
                </Box>
                <TextBold
                  fontSize={[6, 9]}
                  lineHeight={1}
                  css={{}}
                  color={Colors.oc.gray[9]}
                >
                  Overview
                </TextBold>
              </Box>
              <Box mt={2} />
              <Box maxWidth="40ch">
                {state.screen === Screen.Results ? (
                  <Text>
                    We analysed the button's contrast of this page and here is
                    what we found out.
                  </Text>
                ) : (
                  <LoadingLead widths={[400, 350]} />
                )}
                {/* <Box mt={4} />
          <Text>
            We automatically saved this result in a unique URL. Save it so you
            can always revisit the results from today. You can also share it
            with others.
          </Text> */}
              </Box>

              <Box mt={[5, 4]} mb="auto" />
              <Box display={["none", "block"]}>
                <ChartLabels state={state} routeProps={routeProps} />
              </Box>
            </Box>
            <Box ml={7} mr="auto" display={["none", "none", "none"]} />
            {/* BOX RIGHT */}
            <Box flex="1 1 auto" display="flex" justifyContent="center">
              <Box
                css={{ position: "relative" }}
                flex="1 1 auto"
                maxWidth={[300, 350, 400]}
              >
                <SvgDonut
                  isLoading={state.screen !== Screen.Results}
                  id="contrast-level-donut"
                  title={"Color contrast level of buttons"}
                  description={`Donut chart showing total of ${total} buttons. ${fail} buttons fail every color contrast level, ${passAA} meet AA contrast level and ${passAAA} meets AAA contrast level.`}
                  segments={[
                    {
                      color: Colors.tw.green["800"],
                      percentage: (passAAA / total) * 100,
                      title: "AAA",
                      description: `Chart segment spanning ${(passAAA / total) *
                        100}% of the whole, which is ${passAAA} buttons with AAA contrast level.`,
                    },
                    {
                      color: Colors.tw.green["500"],
                      percentage: (passAA / total) * 100,
                      title: "AA",
                      description: `Chart segment spanning ${(passAA / total) *
                        100}% of the whole, which is ${passAA} buttons with AA contrast level.`,
                    },
                    {
                      color: Colors.tw.red["700"],
                      percentage: (fail / total) * 100,
                      title: "Fail",
                      description: `Chart segment spanning ${(fail / total) *
                        100}% of the whole, which is ${fail} buttons that fail every contrast level.`,
                    },
                  ]}
                  thickness={8}
                  bg={Colors.oc.gray[0]}
                />
                {state.screen === Screen.Results && (
                  <>
                    <Box
                      as="p"
                      color={Colors.oc.gray[8]}
                      textAlign="center"
                      mt={-1}
                      css={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <TextBold
                        as="span"
                        lineHeight={0.7}
                        fontSize={[8, 11]}
                        css={{ display: "block" }}
                      >
                        {Object.keys(state.audit.Result.instances).length}
                      </TextBold>
                      <Box mt={3} as="span" />
                      <Text
                        as="span"
                        lineHeight={1}
                        fontSize={[2, 5]}
                        css={{
                          display: "block",
                          textTransform: "uppercase",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Elements<sup>*</sup>
                      </Text>
                      <Text fontSize={[1, 1, 2]} lineHeight={1} as="span">
                        (buttons <em>&</em> links)
                      </Text>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={["flex", "none"]}
          mt={4}
          flex="1 1 auto"
          flexDirection="column"
          alignItems="center"
        >
          <ChartLabels state={state} routeProps={routeProps} />
        </Box>
        <Box width={[1, 1, 1, 1 / 3]} pl={[0, 0, 0, 8]} mt={[7, 8, 8, 0]}>
          <Box display="flex" alignItems="center">
            <Box
              mr={3}
              color={Colors.oc.gray[6]}
              flex="0 0 auto"
              width={24}
              height={24}
              css={{ svg: { width: "100%", height: "auto" } }}
            >
              <Icons.SvgMessagesBubbleSquareInformation aria-hidden="true" />
            </Box>
            <TextBold color={Colors.oc.gray[7]}>
              We exclude some buttons
            </TextBold>
          </Box>
          <Box maxWidth="copy">
            <Text fontSize={[2]}>
              If a button or link doesn't have any visible text, it's too
              complex or too big, it will not be shown.
            </Text>
          </Box>
          <Box mt={[4, 6, 8]} />
          <Box display="flex" alignItems="center">
            <Box
              mr={3}
              color={Colors.oc.gray[6]}
              flex="0 0 auto"
              width={24}
              height={24}
              css={{ svg: { width: "100%", height: "auto" } }}
            >
              <Icons.SvgQuestionHelpMessage aria-hidden="true" />
            </Box>
            <TextBold color={Colors.oc.gray[7]}>
              Help us improve this tool
            </TextBold>
          </Box>
          <Box maxWidth="copy">
            <Text fontSize={[2]}>
              Some buttons might be too complex for us to analyse. If something
              is wrong, give us{" "}
              <LinkAnchor
                rel="noopener noreferrer"
                target="_blank"
                href={`mailto:info@aditus.io?subject=Feedback on Button Contrast Checker&body=Some buttons on this webpage are wrongly shown as errors or there is something wrong with the display of the button.%0D%0A%0D%0A URL: ${window.location.href}`}
              >
                feedback
              </LinkAnchor>{" "}
              so we can fix it.
            </Text>
          </Box>
        </Box>
      </Box>

      {/*<Box
        display="flex"
        justifyContent="space-between"
        flexWrap={["wrap", "wrap", "nowrap"]}
        m={-4}
      >
        {/* <Box flex="1 1 auto" maxWidth={460} p={4}>
          <Box
            border={`1px solid ${Colors.oc.gray[3]}`}
            bg={Colors.oc.gray[0]}
            borderRadius={10}
            py={3}
            px={4}
            display="flex"
          >
            <Box
              flex="0 0 auto"
              display="flex"
              alignItems="flex-start"
              pt={2}
              mr={4}
            >
              <IllustrationScanColors width={40} height={40} />
            </Box>
            <Box flex="1 1 auto">
              <TextBold>What do we test?</TextBold>
              <Text>
                Learn about the accessibility{" "}
                <LinkButton onClick={() => setTabIndex(3)}>
                  guidelines
                </LinkButton>{" "}
                included in our testing.
              </Text>
            </Box>
          </Box> 
        </Box>
      </Box>*/}

      <Box mt={[7, 11]} />
      <Box height={1} bg={Colors.oc.gray[4]} />
    </>
  )
}
