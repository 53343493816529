import * as React from "react"
import { State } from "./interfaces"
import { MatchRenderProps } from "@reach/router"
import { ContrastResultExplanation } from "./ContrastResultExplanation"
import {
  Box,
  Text,
  TextBold,
  Lead,
  Container,
  Heading,
  SubHeading,
  LinkGatsby,
  LinkAnchor,
  LinkArrowGatsby,
  LinkArrowButton,
} from "../../elements"
import { ContrastScoreBox } from "../contrast-and-accessibility/ContrastScoreBox"
import { ButtonInstanceList } from "./ButtonInstanceList"
import { Icons, Colors } from "../../materials"
import { IconText, IconContrast, SectionMessageWithGif } from "../../components"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/core"

export const TabPass: React.FC<{
  state: State
  routeProps: MatchRenderProps<{}>
}> = ({ state, routeProps }) => {
  if (!state.audit) {
    return null
  }

  const passAA = state.audit.Result.buttonsByLevel.AA.length
  const passAAA = state.audit.Result.buttonsByLevel.AAA.length
  const pass = passAA + passAAA

  return (
    <>
      <h3 className="visually-hidden">Sufficient Contrast</h3>
      <Box mt={[6, 9]} />
      {pass === 0 ? (
        <SectionMessageWithGif
          title="Oups!! This is awkward."
          text={() => (
            <Box maxWidth="50ch">
              <Text>
                We couldn't find any buttons that meet text and non-text
                contrast requirements.
              </Text>
              <Box mt={2} />
              <Text>Let's hope something went wrong on our side.</Text>
            </Box>
          )}
          icon="SvgVideoGamePacmanEnemy"
          iconStyle={{
            path: {
              strokeWidth: 1.5,
              stroke: Colors.oc.gray[9],
            },
            ellipse: {
              stroke: Colors.oc.gray[9],
              fill: Colors.oc.white,
            },
            "path:first-of-type": {
              stroke: Colors.oc.gray[9],
              fill: Colors.tw.red["600"],
            },
          }}
          gif={{
            href: "https://giphy.com/gifs/power-rangers-morphin-OOZLyBA9Euq2I",
            src: "/videos/power-ranger-fail.2.gif",
            alt: "Power Rangers Running GIF",
          }}
          dividerProps={{
            bg: Colors.oc.gray[6],
            css: {
              filter: `drop-shadow( 0px 2px 2px ${Colors.oc.gray[5]})`,
            },
          }}
        />
      ) : (
        <>
          {passAAA > 0 ? (
            <>
              <Accordion allowToggle defaultIndex={0}>
                <AccordionItem isDisabled>
                  {({ isExpanded }) => null}
                </AccordionItem>

                <AccordionItem defaultIsOpen={false}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionHeader bg="transparent" border={0} p={0}>
                        <Container maxWidth="l" py={2} bg={Colors.oc.gray[1]}>
                          <Box
                            display="flex"
                            alignItems="center"
                            id="AAA"
                            width={1}
                            css={{ cursor: "pointer", fontSize: "1rem" }}
                          >
                            <Box
                              color={Colors.tw.green["700"]}
                              width={[25, 25, 35]}
                              display="flex"
                              alignItems="center"
                              mr={[2, 3]}
                              css={{
                                svg: {
                                  width: "100%",
                                  height: "auto",
                                  strokeWidth: 2,
                                },
                              }}
                              aria-hidden="true"
                            >
                              <Icons.SvgLoveIt />
                            </Box>
                            <TextBold
                              as="h4"
                              fontSize={[7, 10]}
                              lineHeight={1}
                              color={Colors.tw.green["700"]}
                            >
                              AAA{" "}
                              <TextBold as="span" fontSize={[5, 7]}>
                                [{state.audit.Result.buttonsByLevel.AAA.length}]
                              </TextBold>
                            </TextBold>
                            <Box ml={2} mr="auto" />
                            <Box
                              display="flex"
                              alignItems="center"
                              color={Colors.oc.blue[9]}
                              css={{ textAlign: "right" }}
                            >
                              <TextBold
                                as="span"
                                fontSze={[1, 2]}
                                display={["none", "none", "block"]}
                              >
                                What does this mean?
                              </TextBold>
                              <Box
                                width={30}
                                height={30}
                                css={{
                                  transform: isExpanded
                                    ? "rotate(-90deg)"
                                    : "rotate(90deg)",
                                  svg: { width: "100%", height: "auto" },
                                }}
                              >
                                <Icons.SvgKeyboardArrowRight />
                              </Box>
                            </Box>
                          </Box>
                        </Container>
                      </AccordionHeader>
                      <AccordionPanel p={0} mt={-1}>
                        <Container maxWidth="l" pb={5} bg={Colors.oc.gray[1]}>
                          <Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              flexWrap={["wrap", "wrap", "wrap", "wrap"]}
                            >
                              <Box
                                flex="1 1 auto"
                                mr={[0, 0, 0, 6]}
                                mb={[4, 4, 6, 6]}
                              >
                                <Box maxWidth="50ch">
                                  <Text>
                                    A button is considered AAA when it meets AAA
                                    requirements for text contrast in all its
                                    states. Regarding non-text contrast, as
                                    there is only one requirement for AA,
                                    meeting this is enough for the button to be
                                    considered AAA.
                                  </Text>
                                </Box>
                              </Box>
                              <Box
                                flex={["1 1 auto", "1 1 auto", "1 1 auto", "0"]}
                                display="flex"
                                justifyContent="center"
                                flexWrap={["wrap", "wrap", "nowrap"]}
                              >
                                <Box
                                  flex={["1 1 auto", "0 1 auto", "0"]}
                                  width={["auto", 0.7, "auto"]}
                                >
                                  <ContrastScoreBox
                                    title={() => <>AAA: Text Contrast</>}
                                    icon={() => (
                                      <IconText color={Colors.oc.blue[9]} />
                                    )}
                                    scoreSpacing={2}
                                    scores={[
                                      {
                                        score: () => (
                                          <>
                                            <span
                                              css={{ fontSize: "1.125rem" }}
                                            >
                                              ≥
                                            </span>{" "}
                                            7
                                          </>
                                        ),
                                        suffix: ":1",
                                        label: () => (
                                          <>
                                            <Text
                                              mt={1}
                                              fontSize={2}
                                              color={Colors.oc.blue[9]}
                                              as="span"
                                              textAlign="center"
                                              css={{ whiteSpace: "nowrap" }}
                                            >
                                              <span css={{ display: "block " }}>
                                                small text
                                              </span>
                                              <span css={{ display: "block" }}>
                                                <em>vs.</em> background
                                              </span>
                                            </Text>
                                          </>
                                        ),
                                        labelPosition: "bottom",
                                      },
                                      {
                                        score: () => (
                                          <>
                                            <span
                                              css={{ fontSize: "1.125rem" }}
                                            >
                                              ≥
                                            </span>{" "}
                                            4.5
                                          </>
                                        ),
                                        suffix: ":1",
                                        label: () => (
                                          <>
                                            <Text
                                              mt={1}
                                              fontSize={2}
                                              color={Colors.oc.blue[9]}
                                              as="span"
                                              textAlign="center"
                                              css={{ whiteSpace: "nowrap" }}
                                            >
                                              <span css={{ display: "block " }}>
                                                large text
                                              </span>
                                              <span css={{ display: "block" }}>
                                                <em>vs.</em> background
                                              </span>
                                            </Text>
                                          </>
                                        ),
                                        labelPosition: "bottom",
                                      },
                                    ]}
                                  />
                                </Box>
                                <Box
                                  mr={4}
                                  display={["none", "none", "block"]}
                                />
                                <Box
                                  flex={["1 1 auto", "0 1 auto", "0"]}
                                  mt={[4, 4, 0]}
                                  width={["auto", 0.7, "auto"]}
                                >
                                  <ContrastScoreBox
                                    title={() => <>AA: Non-Text Contrast</>}
                                    icon={() => (
                                      <IconContrast
                                        bg={Colors.oc.gray[3]}
                                        object={Colors.oc.blue[9]}
                                      />
                                    )}
                                    scoreSpacing={2}
                                    scores={[
                                      {
                                        score: () => (
                                          <>
                                            <span
                                              css={{ fontSize: "1.125rem" }}
                                            >
                                              ≥
                                            </span>{" "}
                                            3
                                          </>
                                        ),
                                        suffix: ":1",
                                        label: () => (
                                          <>
                                            <Text
                                              mt={1}
                                              fontSize={2}
                                              color={Colors.oc.blue[9]}
                                              as="span"
                                              textAlign="center"
                                              css={{ whiteSpace: "nowrap" }}
                                            >
                                              <span css={{ display: "block " }}>
                                                object <em>vs.</em>
                                              </span>
                                              <span css={{ display: "block" }}>
                                                adjacent background
                                              </span>
                                            </Text>
                                          </>
                                        ),
                                        labelPosition: "bottom",
                                      },
                                    ]}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Container>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              <Box mt={[4, 6, 8]} />
              <ButtonInstanceList
                instances={state.audit.Result.instances}
                ids={state.audit.Result.buttonsByLevel.AAA}
              />
            </>
          ) : null}

          {passAA > 0 && passAAA > 0 ? (
            <>
              <Box mt={13} />
            </>
          ) : null}

          {passAA > 0 ? (
            <>
              <Accordion allowToggle defaultIndex={0}>
                <AccordionItem isDisabled>
                  {({ isExpanded }) => null}
                </AccordionItem>

                <AccordionItem defaultIsOpen={false}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionHeader bg="transparent" border={0} p={0}>
                        <Container maxWidth="l" py={2} bg={Colors.oc.gray[1]}>
                          <Box
                            display="flex"
                            alignItems="center"
                            id="AAA"
                            width={1}
                            css={{ cursor: "pointer", fontSize: "1rem" }}
                          >
                            <Box
                              color={Colors.tw.green["700"]}
                              width={[25, 25, 35]}
                              display="flex"
                              alignItems="center"
                              mr={[2, 3]}
                              css={{
                                svg: {
                                  width: "100%",
                                  height: "auto",
                                  strokeWidth: 2,
                                },
                              }}
                              aria-hidden="true"
                            >
                              <Icons.SvgLoveIt />
                            </Box>
                            <TextBold
                              as="h4"
                              fontSize={[7, 10]}
                              lineHeight={1}
                              color={Colors.tw.green["700"]}
                            >
                              AA{" "}
                              <TextBold as="span" fontSize={[5, 7]}>
                                [{state.audit.Result.buttonsByLevel.AA.length}]
                              </TextBold>
                            </TextBold>
                            <Box ml={2} mr="auto" />
                            <Box
                              display="flex"
                              alignItems="center"
                              color={Colors.oc.blue[9]}
                              css={{ textAlign: "right" }}
                            >
                              <TextBold
                                as="span"
                                fontSze={[1, 2]}
                                display={["none", "none", "block"]}
                              >
                                What does this mean?
                              </TextBold>
                              <Box
                                width={30}
                                height={30}
                                css={{
                                  transform: isExpanded
                                    ? "rotate(-90deg)"
                                    : "rotate(90deg)",
                                  svg: { width: "100%", height: "auto" },
                                }}
                              >
                                <Icons.SvgKeyboardArrowRight />
                              </Box>
                            </Box>
                          </Box>
                        </Container>
                      </AccordionHeader>
                      <AccordionPanel p={0} mt={-1}>
                        <Container maxWidth="l" pb={5} bg={Colors.oc.gray[1]}>
                          <Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              flexWrap={["wrap", "wrap", "wrap", "wrap"]}
                            >
                              <Box
                                flex="1 1 auto"
                                mr={[0, 0, 0, 6]}
                                mb={[4, 4, 6, 6]}
                              >
                                <Box maxWidth="50ch">
                                  <Text>
                                    A button is considered AA when it meets AA
                                    requirements for text contrast and non-text
                                    contrast in all its states. If the button
                                    meets AAA requirements but not in all its
                                    states, it's still considered to be AA.
                                  </Text>
                                </Box>
                              </Box>
                              <Box
                                flex={["1 1 auto", "1 1 auto", "1 1 auto", "0"]}
                                display="flex"
                                justifyContent="center"
                                flexWrap={["wrap", "wrap", "nowrap"]}
                              >
                                <Box
                                  flex={["1 1 auto", "0 1 auto", "0"]}
                                  width={["auto", 0.7, "auto"]}
                                >
                                  <ContrastScoreBox
                                    title={() => <>AA: Text Contrast</>}
                                    icon={() => (
                                      <IconText color={Colors.oc.blue[9]} />
                                    )}
                                    scoreSpacing={2}
                                    scores={[
                                      {
                                        score: () => (
                                          <>
                                            <span
                                              css={{ fontSize: "1.125rem" }}
                                            >
                                              ≥
                                            </span>{" "}
                                            4.5
                                          </>
                                        ),
                                        suffix: ":1",
                                        label: () => (
                                          <>
                                            <Text
                                              mt={1}
                                              fontSize={2}
                                              color={Colors.oc.blue[9]}
                                              as="span"
                                              textAlign="center"
                                              css={{ whiteSpace: "nowrap" }}
                                            >
                                              <span css={{ display: "block " }}>
                                                small text
                                              </span>
                                              <span css={{ display: "block" }}>
                                                <em>vs.</em> background
                                              </span>
                                            </Text>
                                          </>
                                        ),
                                        labelPosition: "bottom",
                                      },
                                      {
                                        score: () => (
                                          <>
                                            <span
                                              css={{ fontSize: "1.125rem" }}
                                            >
                                              ≥
                                            </span>{" "}
                                            3
                                          </>
                                        ),
                                        suffix: ":1",
                                        label: () => (
                                          <>
                                            <Text
                                              mt={1}
                                              fontSize={2}
                                              color={Colors.oc.blue[9]}
                                              as="span"
                                              textAlign="center"
                                              css={{ whiteSpace: "nowrap" }}
                                            >
                                              <span css={{ display: "block " }}>
                                                large text
                                              </span>
                                              <span css={{ display: "block" }}>
                                                <em>vs.</em> background
                                              </span>
                                            </Text>
                                          </>
                                        ),
                                        labelPosition: "bottom",
                                      },
                                    ]}
                                  />
                                </Box>
                                <Box
                                  mr={4}
                                  display={["none", "none", "block"]}
                                />
                                <Box
                                  flex={["1 1 auto", "0 1 auto", "0"]}
                                  mt={[4, 4, 0]}
                                  width={["auto", 0.7, "auto"]}
                                >
                                  <ContrastScoreBox
                                    title={() => <>AA: Non-Text Contrast</>}
                                    icon={() => (
                                      <IconContrast
                                        bg={Colors.oc.gray[3]}
                                        object={Colors.oc.blue[9]}
                                      />
                                    )}
                                    scoreSpacing={2}
                                    scores={[
                                      {
                                        score: () => (
                                          <>
                                            <span
                                              css={{ fontSize: "1.125rem" }}
                                            >
                                              ≥
                                            </span>{" "}
                                            3
                                          </>
                                        ),
                                        suffix: ":1",
                                        label: () => (
                                          <>
                                            <Text
                                              mt={1}
                                              fontSize={2}
                                              color={Colors.oc.blue[9]}
                                              as="span"
                                              textAlign="center"
                                              css={{ whiteSpace: "nowrap" }}
                                            >
                                              <span css={{ display: "block " }}>
                                                object <em>vs.</em>
                                              </span>
                                              <span css={{ display: "block" }}>
                                                adjacent background
                                              </span>
                                            </Text>
                                          </>
                                        ),
                                        labelPosition: "bottom",
                                      },
                                    ]}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Container>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Box mt={[4, 6, 8]} />

              <ButtonInstanceList
                instances={state.audit.Result.instances}
                ids={state.audit.Result.buttonsByLevel.AA}
              />
            </>
          ) : null}
        </>
      )}
      {pass > 0 ? <Box height={1} bg={Colors.oc.gray[4]} /> : null}
      <Box mt={[6, 6, 6]} textAlign="center">
        <LinkArrowGatsby to={`${routeProps.location.pathname}?failing`}>
          Buttons with failing contrast
        </LinkArrowGatsby>
      </Box>
      <Box mt={[7, 7, 10]} />
    </>
  )
}
