import * as React from "react"
import { State } from "./interfaces"
import { Colors, Icons } from "../../materials"
import { MatchRenderProps } from "@reach/router"
import { ContrastScoreBox } from "../contrast-and-accessibility/ContrastScoreBox"
import {
  ButtonAnchor,
  ButtonTheme,
  Box,
  Text,
  TextBold,
  LinkArrowGatsby,
  Lead,
  Container,
  LinkArrowButton,
  LinkButton,
} from "../../elements"
import { openInNewWindow } from "../../utils/handlers/open-href-in-new-window"
import { ButtonInstanceList } from "./ButtonInstanceList"
import { IconText, IconContrast, SectionMessageWithGif } from "../../components"
import { ContrastResultExplanation } from "./ContrastResultExplanation"
import { ButtonShare } from "./ButtonShare"

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/core"

export const TabFail: React.FC<{
  state: State
  routeProps: MatchRenderProps<{}>
}> = ({ state, routeProps }) => {
  if (!state.audit) {
    return null
  }

  const fail = state.audit.Result.buttonsByLevel.fail.any.length

  return (
    <>
      <h3 className="visually-hidden">Failing Contrast</h3>
      <Box mt={[6, 9]} />
      {fail === 0 ? (
        <SectionMessageWithGif
          title="Hooray! You are the best."
          text={() => (
            <Box maxWidth="50ch">
              <Text>
                We couldn't find any errors. All buttons meet text and non-text
                contrast requirements.
              </Text>

              <Box mt={2} />
              <TextBold>Congratulations!</TextBold>
            </Box>
          )}
          icon="SvgLoveIt"
          iconStyle={{
            filter: `drop-shadow( 3px 5px 2px ${Colors.oc.blue[3]})`,
            path: {
              strokeWidth: 1.5,
              stroke: Colors.oc.blue[9],
              fill: Colors.oc.yellow[5],
            },
          }}
          gif={{
            href: "https://giphy.com/gifs/explosion-heaven-lxcz7ntpCKJfq",
            src: "/videos/power-ranger-success.2.gif",
            alt: "Power Rangers Success GIF",
          }}
          dividerProps={{
            bg: Colors.oc.yellow[5],
            css: {
              filter: `drop-shadow( 0px 2px 3px ${Colors.tw.red["200"]})`,
            },
          }}
        />
      ) : (
        <>
          <Accordion allowToggle defaultIndex={0}>
            <AccordionItem isDisabled>{({ isExpanded }) => null}</AccordionItem>

            <AccordionItem defaultIsOpen={false}>
              {({ isExpanded }) => (
                <>
                  <AccordionHeader bg="transparent" border={0} p={0}>
                    <Container maxWidth="l" py={2} bg={Colors.oc.gray[1]}>
                      <Box
                        display="flex"
                        alignItems="center"
                        id="FAIL"
                        width={1}
                        css={{ cursor: "pointer", fontSize: "1rem" }}
                      >
                        <Box
                          color={Colors.tw.red["700"]}
                          width={[25, 25, 35]}
                          display="flex"
                          alignItems="center"
                          mr={[2, 3]}
                          css={{
                            svg: {
                              width: "100%",
                              height: "auto",
                              strokeWidth: 2,
                            },
                          }}
                          aria-hidden="true"
                        >
                          <Icons.SvgVideoGamePacmanEnemy />
                        </Box>
                        <TextBold
                          as="h4"
                          fontSize={[7, 10]}
                          lineHeight={1}
                          color={Colors.tw.red["700"]}
                        >
                          FAIL{" "}
                          <TextBold as="span" fontSize={[5, 7]}>
                            [
                            {state.audit.Result.buttonsByLevel.fail.fail.length}
                            ]
                          </TextBold>
                        </TextBold>
                        <Box ml={2} mr="auto" />
                        <Box
                          display="flex"
                          alignItems="center"
                          color={Colors.oc.blue[9]}
                          css={{ textAlign: "right" }}
                        >
                          <TextBold
                            as="span"
                            fontSze={[1, 2]}
                            display={["none", "none", "block"]}
                          >
                            What does this mean?
                          </TextBold>
                          <Box
                            width={30}
                            height={30}
                            css={{
                              transform: isExpanded
                                ? "rotate(-90deg)"
                                : "rotate(90deg)",
                              svg: { width: "100%", height: "auto" },
                            }}
                          >
                            <Icons.SvgKeyboardArrowRight />
                          </Box>
                        </Box>
                      </Box>
                    </Container>
                  </AccordionHeader>
                  <AccordionPanel p={0} mt={-1}>
                    <Container maxWidth="l" pb={5} bg={Colors.oc.gray[1]}>
                      <Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          flexWrap={["wrap", "wrap", "wrap", "wrap"]}
                        >
                          <Box
                            flex="1 1 auto"
                            mr={[0, 0, 0, 6]}
                            mb={[4, 4, 6, 6]}
                          >
                            <Box maxWidth="50ch">
                              <Text>
                                A button is considered to FAIL when it doesn't
                                meet text or non-text contrast requirements in
                                at least one of the three states we check.
                              </Text>
                            </Box>
                          </Box>
                          <Box
                            flex={["1 1 auto", "1 1 auto", "1 1 auto", "0"]}
                            display="flex"
                            justifyContent="center"
                            flexWrap={["wrap", "wrap", "nowrap"]}
                          >
                            <Box
                              flex={["1 1 auto", "0 1 auto", "0"]}
                              width={["auto", 0.7, "auto"]}
                            >
                              <ContrastScoreBox
                                title={() => <>FAIL: Text Contrast</>}
                                icon={() => (
                                  <IconText color={Colors.oc.blue[9]} />
                                )}
                                scoreSpacing={2}
                                scores={[
                                  {
                                    score: () => (
                                      <>
                                        <span css={{ fontSize: "1.125rem" }}>
                                          {"<"}
                                        </span>{" "}
                                        4.5
                                      </>
                                    ),
                                    suffix: ":1",
                                    label: () => (
                                      <>
                                        <Text
                                          mt={1}
                                          fontSize={2}
                                          color={Colors.oc.blue[9]}
                                          as="span"
                                          textAlign="center"
                                          css={{ whiteSpace: "nowrap" }}
                                        >
                                          <span css={{ display: "block " }}>
                                            small text
                                          </span>
                                          <span css={{ display: "block" }}>
                                            <em>vs.</em> background
                                          </span>
                                        </Text>
                                      </>
                                    ),
                                    labelPosition: "bottom",
                                  },
                                  {
                                    score: () => (
                                      <>
                                        <span css={{ fontSize: "1.125rem" }}>
                                          {"<"}
                                        </span>{" "}
                                        3
                                      </>
                                    ),
                                    suffix: ":1",
                                    label: () => (
                                      <>
                                        <Text
                                          mt={1}
                                          fontSize={2}
                                          color={Colors.oc.blue[9]}
                                          as="span"
                                          textAlign="center"
                                          css={{ whiteSpace: "nowrap" }}
                                        >
                                          <span css={{ display: "block " }}>
                                            large text
                                          </span>
                                          <span css={{ display: "block" }}>
                                            <em>vs.</em> background
                                          </span>
                                        </Text>
                                      </>
                                    ),
                                    labelPosition: "bottom",
                                  },
                                ]}
                              />
                            </Box>
                            <Box mr={4} display={["none", "none", "block"]} />
                            <Box
                              flex={["1 1 auto", "0 1 auto", "0"]}
                              mt={[4, 4, 0]}
                              width={["auto", 0.7, "auto"]}
                            >
                              <ContrastScoreBox
                                title={() => <>FAIL: Non-Text Contrast</>}
                                icon={() => (
                                  <IconContrast
                                    bg={Colors.oc.gray[3]}
                                    object={Colors.oc.blue[9]}
                                  />
                                )}
                                scoreSpacing={2}
                                scores={[
                                  {
                                    score: () => (
                                      <>
                                        <span css={{ fontSize: "1.125rem" }}>
                                          {"<"}
                                        </span>{" "}
                                        3
                                      </>
                                    ),
                                    suffix: ":1",
                                    label: () => (
                                      <>
                                        <Text
                                          mt={1}
                                          fontSize={2}
                                          color={Colors.oc.blue[9]}
                                          as="span"
                                          textAlign="center"
                                          css={{ whiteSpace: "nowrap" }}
                                        >
                                          <span css={{ display: "block " }}>
                                            object <em>vs.</em>
                                          </span>
                                          <span css={{ display: "block" }}>
                                            adjacent background
                                          </span>
                                        </Text>
                                      </>
                                    ),
                                    labelPosition: "bottom",
                                  },
                                ]}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Container>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>

          <Box mt={[4, 6, 8]} />
          <ButtonInstanceList
            instances={state.audit.Result.instances}
            ids={state.audit.Result.buttonsByLevel.fail.fail}
          />

          {state.audit!.Result.buttonsByLevel.fail.unknown.length > 0 ? (
            <>
              <Accordion allowToggle defaultIndex={0}>
                <AccordionItem isDisabled>
                  {({ isExpanded }) => null}
                </AccordionItem>

                <AccordionItem defaultIsOpen={false}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionHeader bg="transparent" border={0} p={0}>
                        <Container maxWidth="l" py={2} bg={Colors.oc.gray[1]}>
                          <Box
                            display="flex"
                            alignItems="center"
                            id="FAIL"
                            width={1}
                            css={{ cursor: "pointer", fontSize: "1rem" }}
                          >
                            <Box
                              color={Colors.tw.red["700"]}
                              width={[25, 25, 35]}
                              display="flex"
                              alignItems="center"
                              mr={[2, 3]}
                              css={{
                                svg: {
                                  width: "100%",
                                  height: "auto",
                                  strokeWidth: 2,
                                },
                              }}
                              aria-hidden="true"
                            >
                              <Icons.SvgVideoGamePacmanEnemy />
                            </Box>
                            <TextBold
                              as="h4"
                              fontSize={[7, 10]}
                              lineHeight={1}
                              color={Colors.tw.red["700"]}
                            >
                              UNKNOWN{" "}
                              <TextBold as="span" fontSize={[5, 7]}>
                                [
                                {
                                  state.audit.Result.buttonsByLevel.fail.unknown
                                    .length
                                }
                                ]
                              </TextBold>
                            </TextBold>
                            <Box ml={2} mr="auto" />
                            <Box
                              display="flex"
                              alignItems="center"
                              color={Colors.oc.blue[9]}
                              css={{ textAlign: "right" }}
                            >
                              <TextBold
                                as="span"
                                fontSze={[1, 2]}
                                display={["none", "none", "block"]}
                              >
                                What does this mean?
                              </TextBold>
                              <Box
                                width={30}
                                height={30}
                                css={{
                                  transform: isExpanded
                                    ? "rotate(-90deg)"
                                    : "rotate(90deg)",
                                  svg: { width: "100%", height: "auto" },
                                }}
                              >
                                <Icons.SvgKeyboardArrowRight />
                              </Box>
                            </Box>
                          </Box>
                        </Container>
                      </AccordionHeader>
                      <AccordionPanel p={0} mt={-1}>
                        <Container maxWidth="l" pb={5} bg={Colors.oc.gray[1]}>
                          <Box>
                            <Box display="flex" flexWrap={["wrap", "nowrap"]}>
                              <Box maxWidth="50ch">
                                <Text>
                                  Some buttons might be too complex for us to
                                  automatically analyse. We still consider the
                                  buttons to be failing, but you should manually
                                  check their contrast.
                                </Text>
                              </Box>
                            </Box>
                          </Box>
                        </Container>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>

              <Box mt={[4, 6, 8]} />
              <ButtonInstanceList
                instances={state.audit.Result.instances}
                ids={state.audit.Result.buttonsByLevel.fail.unknown}
                showCheckerBackground={true}
              />
            </>
          ) : null}
        </>
      )}
      {fail > 0 ? <Box height={1} bg={Colors.oc.gray[4]} /> : null}

      <Box mt={[6, 6, 6]} textAlign="center">
        <LinkArrowGatsby to={`${routeProps.location.pathname}?success`}>
          Buttons with sufficient contrast
        </LinkArrowGatsby>
      </Box>

      <Box mt={[7, 7, 10]} />
    </>
  )
}
