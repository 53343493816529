import * as React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import {
  Box,
  SubHeading,
  Text,
  Container,
  Lead,
  Heading,
  TextBold,
  List,
  ListItem,
} from "../../elements"

import { Colors, Icons } from "../../materials"
import {
  IconObjectBackgroundColor,
  IconTextBackgroundColor,
  SvgDonut,
} from "../../components"

export const ButtonWithStates = styled.div({
  fontWeight: "400",
  textAlign: "center",
  verticalAlign: "middle",
  userSelect: "none",
  border: "1px solid transparent",
  padding: ".375rem .75rem",
  fontSize: "1rem",
  lineHeight: "1.5",
  borderRadius: ".25rem",
  transition:
    "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  color: "#fff",
  backgroundColor: "#007bff",
  borderColor: "#007bff",
  cursor: "pointer",
  minWidth: "100px",
  "&:hover, &.hover": {
    color: "#fff",
    backgroundColor: "#0069d9",
    borderColor: "#0062cc",
  },
  "&:focus, &.focus": {
    boxShadow: "0 0 0 0.2rem rgba(38,143,255,.5)",
    outline: "none",
  },
})

export const Features: React.FC<{}> = () => {
  const { dashboard } = useStaticQuery(graphql`
    query {
      dashboard: file(
        relativePath: { eq: "images/color-check-dashboard.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const total = 12
  const passAA = 3
  const passAAA = 2
  const fail = 7

  return (
    <Box bg={Colors.oc.white} borderTop={`1px solid ${Colors.oc.gray[2]}`}>
      <Container maxWidth="m" pt={[7, 8, 9, 10]} pb={[7, 8]}>
        <Heading color={Colors.tw.pink["600"]}>
          Another contrast checker? Yikes!
        </Heading>
        <Box mt={2} />
        <Box maxWidth="copy">
          <Lead>
            We were in need of a tool to test the contrast of all buttons and
            links of a page at once. So we built Button Contrast Checker. You
            can use it for free.
          </Lead>
        </Box>

        <Box mt={[8, 8, 8, 10]} />
        <Box
          display="flex"
          flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
          alignItems="flex-start"
          m={[-4, -6]}
        >
          <Box
            p={[4, 6]}
            maxWidth="copy"
            width={[1, 1, "auto"]}
            flex="0 0 auto"
          >
            <SubHeading color={Colors.oc.blue[9]}>
              We test default, hover and focus.
            </SubHeading>
            <Box mt={2} />
            <Box>
              <Text>
                Buttons have several states: <em>default</em>, <em>hover</em>,{" "}
                <em>focus</em>, <em>active</em>, <em>disabled</em>, etc.
              </Text>
              <Box mt={2} />
              <Text>
                Many websites have insufficient contrast in these states. Maybe
                it&apos;s because the tools don&apos;t help much in this regard,
                but not anymore. In a few seconds you&apos;ll know if your
                buttons and links have enough contrast in the three most common
                states.
              </Text>
            </Box>
          </Box>
          <Box
            p={[4, 6]}
            flex="1 1 auto"
            display="flex"
            justifyContent={["center", "center", "center", "flex-end"]}
          >
            <Box
              width={[0.7, "auto"]}
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              border={`2px solid ${Colors.oc.gray[4]}`}
              borderRadius={10}
              bg={Colors.oc.white}
              py={[3, 0]}
            >
              <Box
                width={[1, 1 / 3]}
                px={6}
                py={[3, 5]}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="center">
                  <ButtonWithStates>default</ButtonWithStates>
                </Box>

                <Box mt={[2, 5]} display="flex" justifyContent="center">
                  <IconTextBackgroundColor
                    color="#fff"
                    backgroundColor="#007BFF"
                    contrastScore={{
                      contrast: 3.97,
                      passesAA: false,
                      passesAAA: false,
                      color1: "#fff",
                      color2: "#007BFF",
                    }}
                  />
                </Box>
              </Box>
              <Box
                width={[1, 1 / 3]}
                px={6}
                py={[3, 5]}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  css={{ position: "relative" }}
                >
                  <ButtonWithStates className="hover">hover</ButtonWithStates>
                  <Box
                    width={24}
                    height={24}
                    color={Colors.oc.gray[7]}
                    css={{
                      position: "absolute",
                      bottom: -10,
                      right: -5,
                      svg: {
                        width: "100%",
                        height: "auto",
                        color: "inherit",
                        path: {
                          fill: "#fff",
                        },
                      },
                    }}
                  >
                    <Icons.SvgHand />
                  </Box>
                </Box>

                <Box mt={[2, 5]} display="flex" justifyContent="center">
                  <IconTextBackgroundColor
                    color="#fff"
                    backgroundColor="#0069d9"
                    contrastScore={{
                      contrast: 5.22,
                      passesAA: true,
                      passesAAA: false,
                      color1: "#fff",
                      color2: "#0069d9",
                    }}
                  />
                </Box>
              </Box>
              <Box
                width={[1, 1 / 3]}
                px={6}
                py={[3, 5]}
                display="flex"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
              >
                <Box display="flex" justifyContent="center">
                  <ButtonWithStates className="focus">focus</ButtonWithStates>
                </Box>
                <Box mt={[3, 5]} display="flex" justifyContent="center">
                  <Box px={2}>
                    <IconTextBackgroundColor
                      color="#fff"
                      backgroundColor="#007BFF"
                      contrastScore={{
                        contrast: 3.97,
                        passesAA: false,
                        passesAAA: false,
                        color1: "#fff",
                        color2: "#007BFF",
                      }}
                    />
                  </Box>
                  <Box px={2}>
                    <IconObjectBackgroundColor
                      objectBackgroundColor="#9BC8FF"
                      containerBackgroundColor="#fff"
                      contrastScore={{
                        contrast: 1.64,
                        passesAA: true,
                        passesAAA: false,
                        color1: "#fff",
                        color2: "#9BC8FF",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={[8, 8, 8, 10]} />
        <Box
          display="flex"
          flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
          alignItems="flex-start"
          m={[-4, -6]}
        >
          <Box
            width={[1, "auto"]}
            p={[4, 6]}
            flex="0 0 auto"
            order={[0, 0, 0, 1]}
          >
            <SubHeading color={Colors.oc.blue[9]}>
              Button background vs. Adjacent background.
            </SubHeading>
            <Box mt={4} />
            <Box maxWidth="copy">
              <Text>
                Let's picture this scenario: we launch a website with perfect
                buttons and great contrast. Later down the road, unaware of the
                accessibility implications, we change the background of sections
                of the website. This might make some buttons fail contrast with
                adjacent colors.
              </Text>
              <Box mt={2} />
              <Text>
                Add this tool to your workflow and prevent going live with
                less-than-perfect buttons.
              </Text>
            </Box>
          </Box>
          <Box
            p={[4, 6]}
            flex="1 1 auto"
            order={[1, 1, 1, 0]}
            display="flex"
            justifyContent="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItem="center"
              justifyContent="center"
              width={["auto", "auto", "auto", 1]}
              border={`2px solid ${Colors.oc.gray[4]}`}
              borderRadius={10}
              css={{
                overflow: "hidden",
              }}
            >
              <Box
                bg={Colors.tw.blue["500"]}
                py={5}
                px={[6, 8]}
                width={1}
                display="flex"
                justifyContent="center"
              >
                <Box
                  tabIndex={-1}
                  px={3}
                  py={2}
                  border={0}
                  bg={Colors.tw.blue["700"]}
                  color={Colors.oc.white}
                  fontSize={3}
                  borderRadius={4}
                >
                  I am a button
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                py={4}
                px={[6, 8]}
              >
                <IconTextBackgroundColor
                  color={Colors.oc.white}
                  backgroundColor={Colors.tw.blue["700"]}
                  contrastScore={{
                    contrast: 5.42,
                    passesAA: true,
                    passesAAA: false,
                    color1: Colors.oc.white,
                    color2: Colors.tw.blue["700"],
                  }}
                />
                <Box ml={6} />
                <IconObjectBackgroundColor
                  objectBackgroundColor={Colors.tw.blue["700"]}
                  containerBackgroundColor={Colors.tw.blue["500"]}
                  contrastScore={{
                    contrast: 1.77,
                    passesAA: false,
                    passesAAA: false,
                    color1: Colors.tw.blue["500"],
                    color2: Colors.tw.blue["700"],
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box mt={[8, 8, 8, 10]} />
        <Box
          display="flex"
          flexWrap={["wrap", "wrap", "wrap", "nowrap"]}
          alignItems="flex-start"
          m={[-4, -6]}
        >
          <Box p={[4, 6]} maxWidth="copy">
            <SubHeading color={Colors.oc.blue[9]}>
              Save and share results with your team.
            </SubHeading>
            <Box mt={4} />
            <Box maxWidth="copy">
              <Text>
                Each time you scan a page, we store the results in a unique URL.
                You can send it to your team or save it for future comparisons.
              </Text>
            </Box>
          </Box>
          <Box
            p={[4, 6]}
            flex="1 1 auto"
            display="flex"
            alignItem="center"
            justifyContent={["center", "center", "center", "flex-end"]}
          >
            <Box
              as="figure"
              display="flex"
              flexDirection={["column-reverse", "row"]}
              alignItems="center"
              border={`2px solid ${Colors.oc.gray[4]}`}
              borderRadius={10}
              py={3}
              px={5}
            >
              <figcaption>
                <List>
                  <ListItem display="flex" alignItems="center">
                    <Box
                      mr={2}
                      width={40}
                      height={16}
                      bg={Colors.tw.green["800"]}
                    />
                    <TextBold
                      fontSize={2}
                      css={{ textTransform: "uppercase" }}
                      color={Colors.oc.gray[8]}
                    >
                      AAA
                    </TextBold>
                    <Box ml={7} mr="auto" />
                    <TextBold>{passAAA}</TextBold>
                  </ListItem>
                  <ListItem display="flex" alignItems="center" mt={2}>
                    <Box
                      mr={2}
                      width={40}
                      height={16}
                      bg={Colors.tw.green["500"]}
                    />
                    <TextBold
                      fontSize={2}
                      css={{ textTransform: "uppercase" }}
                      color={Colors.oc.gray[8]}
                    >
                      AA
                    </TextBold>
                    <Box ml={7} mr="auto" />
                    <TextBold>{passAA}</TextBold>
                  </ListItem>
                  <ListItem display="flex" alignItems="center" mt={2}>
                    <Box
                      mr={2}
                      width={40}
                      height={16}
                      bg={Colors.tw.red["700"]}
                    />
                    <TextBold
                      fontSize={2}
                      css={{ textTransform: "uppercase" }}
                      color={Colors.oc.gray[8]}
                    >
                      Fail
                    </TextBold>
                    <Box ml={7} mr="auto" />
                    <TextBold>{fail}</TextBold>
                  </ListItem>
                </List>
              </figcaption>
              <Box mr={8} />
              <Box maxWidth={220} css={{ position: "relative" }}>
                <SvgDonut
                  id="contrast-level-donut"
                  title={"Color contrast level of buttons"}
                  description={`Donut chart showing total of ${total} buttons. ${fail} buttons fail every color contrast level, ${passAA} meet AA contrast level and ${passAAA} meets AAA contrast level.`}
                  segments={[
                    {
                      color: Colors.tw.green["800"],
                      percentage: (passAAA / total) * 100,
                      title: "AAA",
                      description: `Chart segment spanning ${(passAAA / total) *
                        100}% of the whole, which is ${passAAA} buttons with AAA contrast level.`,
                    },
                    {
                      color: Colors.tw.green["500"],
                      percentage: (passAA / total) * 100,
                      title: "AA",
                      description: `Chart segment spanning ${(passAA / total) *
                        100}% of the whole, which is ${passAA} buttons with AA contrast level.`,
                    },
                    {
                      color: Colors.tw.red["700"],
                      percentage: (fail / total) * 100,
                      title: "Fail",
                      description: `Chart segment spanning ${(fail / total) *
                        100}% of the whole, which is ${fail} buttons that fail every contrast level.`,
                    },
                  ]}
                  thickness={7}
                  bg={Colors.oc.gray[0]}
                />
                <Box
                  as="p"
                  color={Colors.oc.gray[8]}
                  textAlign="center"
                  mt={-1}
                  css={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <TextBold
                    as="span"
                    lineHeight={0.7}
                    fontSize={[8, 8, 6, 8]}
                    css={{
                      display: "block",
                    }}
                  >
                    {total}
                  </TextBold>
                  <Text
                    as="span"
                    mt={1}
                    lineHeight={1}
                    fontSize={2}
                    css={{
                      display: "block",
                      textTransform: "uppercase",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Elements
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
