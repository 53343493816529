import * as React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog"
import { Breakpoints, Colors, Icons } from "../../materials"
import {
  ButtonIcon,
  Button,
  ButtonTheme,
  Box,
  Heading,
  Text,
  Input,
  Container,
} from "../../elements"
import IllustrationShareBg from "../../../static/illustrations/button-contrast-checker/share-bg.inline.svg"

export const ButtonShare: React.FC<{}> = ({}) => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState<boolean>(
    false
  )
  const [isSharedDialogOpened, setIsSharedDialogOpened] = React.useState<
    boolean
  >(false)

  return (
    <>
      <Button
        theme={ButtonTheme.MAIN}
        onClick={() => setIsSharedDialogOpened(true)}
      >
        <Box display="flex">
          <Box mr={2} ml={-1} width={[16, 20]} height={20}>
            <Icons.SvgSend />
          </Box>
        </Box>
        Share
        <Box as="span" display={["none", "inline"]}>
          &nbsp;Results
        </Box>
      </Button>

      {isSharedDialogOpened && (
        <Dialog onDismiss={() => setIsSharedDialogOpened(false)}>
          <Box
            m="10vh auto"
            bg={Colors.oc.white}
            maxWidth={["90vw", "90vw", "70vw", "50vw"]}
            css={{ position: "relative" }}
          >
            <Box
              css={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              pt={3}
              pr={3}
            >
              <ButtonIcon
                aria-label="Close"
                onClick={() => setIsSharedDialogOpened(false)}
              >
                <Icons.SvgClose />
              </ButtonIcon>
            </Box>
            <Box
              bg={Colors.oc.gray[2]}
              css={{
                svg: {
                  width: "100%",
                  height: "auto",
                  display: "block",
                },
              }}
            >
              <IllustrationShareBg aria-hidden="true" />
            </Box>
            <Container py={6}>
              <Heading color={Colors.oc.blue[9]}>Share</Heading>
              <Box maxWidth="copy" mt={2}>
                <Text>
                  We save all checks with an unique URL. This means that you can
                  share the results of this check by sharing the URL of this
                  page.
                </Text>
              </Box>
              <Box mt={6} />

              <Box display="flex" flexDirection={["column", "column", "row"]}>
                <Input
                  value={window.location.href}
                  css={{
                    flex: "1 1 auto",
                    [`@media (min-width: ${Breakpoints[1]})`]: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                />
                <Box mt={3} display={["block", "block", "none"]} />
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setIsCopiedToClipboard(true)}
                  options={{
                    format: "text/plain",
                  }}
                >
                  <Button
                    theme={ButtonTheme.MAIN}
                    css={{
                      [`@media (min-width: ${Breakpoints[1]})`]: {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                    disabled={isCopiedToClipboard}
                  >
                    {isCopiedToClipboard ? "Copied" : "Copy URL to Clipboard"}
                  </Button>
                </CopyToClipboard>
              </Box>
            </Container>
          </Box>
        </Dialog>
      )}
    </>
  )
}
