import ky from "ky"
import {
  Args,
  ChecksColorsButtonsItem,
} from "../../../backend/functions/contrast-check/interfaces"

const API_ENDPOINT = `${process.env.GATSBY_API_URL}/checks/colors/buttons`

export async function getColorsCheck(
  args: Args
): Promise<ChecksColorsButtonsItem> {
  return (await ky
    .post(API_ENDPOINT, { json: args, timeout: false })
    .json()) as ChecksColorsButtonsItem
}
