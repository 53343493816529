import ky from "ky"
import { ReturnValue } from "../../../backend/functions/validate-url/interfaces"

const API_ENDPOINT = `${process.env.GATSBY_API_URL}/validate-url`

export async function validateUrl(url: string): Promise<ReturnValue> {
  return (await ky
    .post(API_ENDPOINT, { json: { url }, timeout: false })
    .json()) as ReturnValue
}
