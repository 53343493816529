import * as React from "react"
import styled from "@emotion/styled"
import useInterval from "@use-it/interval"
import { Router, Location, MatchRenderProps } from "@reach/router"
import pathToRegexp from "path-to-regexp"
import { navigate } from "@reach/router"
import { HTTPError } from "ky"
import IllustrationScanColors from "../../static/illustrations/button-contrast-checker/scan-colors.inline.svg"

import {
  ChecksColorsButtonsItem,
  NotFoundError,
} from "../../backend/functions/contrast-check/interfaces"
import {
  Box,
  Container,
  Text,
  TextBold,
  Title,
  Heading,
  LinkButton,
  SubHeading,
  Lead,
  List,
  ListItem,
  LinkAnchor,
  Pill,
  PillType,
  LoadingDots,
  PillSize,
  ButtonTheme,
  Button,
  LinkGatsby,
  Label,
} from "../elements"
import { Colors, Icons } from "../materials"
import {
  SEO,
  Header,
  Footer,
  SectionMessageWithGif,
  ErrorBoundary,
} from "../components"
import { getColorsCheck } from "../utils/api/get-colors-check"
import { Nullable } from "../utils/types/Nullable"
import {
  State,
  Screen,
  ActionType,
  Action,
  StateLoadingForm,
  ActionFetchResults,
  StateFetchingResults,
} from "../partials/button-contrast-check/interfaces"
import { reducer } from "../partials/button-contrast-check/reducer"
import { FormUrl } from "../partials/button-contrast-check/FormUrl"
import { ButtonShare } from "../partials/button-contrast-check/ButtonShare"
import { AuditResults } from "../partials/button-contrast-check/AuditResults"
import { Info } from "../partials/button-contrast-check/Info"
import { SectionCommunity } from "../partials/SectionCommunity"
import { Features } from "../partials/button-contrast-check/Features"

const hasAudit = (state: State) =>
  state.screen === Screen.Results && state.audit
const isLoadingAudit = (state: State) => state.screen === Screen.LoadingSlug

const LabelIconUrl: React.FC<{ url: string }> = ({ url }) => (
  <Box display="flex" alignItems="center" color={Colors.oc.gray[8]}>
    <Box
      color={Colors.oc.gray[7]}
      display="flex"
      alignItems="center"
      width={24}
      height={24}
      mr={2}
      css={{
        transform: "translateY(-0px)",
        svg: { color: "inherit", width: "100%", height: "auto" },
      }}
    >
      <Icons.SvgInsertLink />
    </Box>
    <Text lineHeight={1} fontSize={[0, 1]}>
      {url}
    </Text>
  </Box>
)

const LabelIconDate: React.FC<{ date?: Date }> = ({ date }) => (
  <Box display="flex" alignItems="center" color={Colors.oc.gray[8]}>
    <Box
      color={Colors.oc.gray[7]}
      display="flex"
      alignItems="center"
      width={24}
      height={24}
      mr={2}
      css={{
        transform: "translateY(-0px)",
        svg: { color: "inherit", width: "100%", height: "auto" },
      }}
    >
      <Icons.SvgToday />
    </Box>
    {date ? (
      <Text as="time" lineHeight={1} fontSize={[0, 1]}>
        {date.toLocaleString("en", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          // timeZoneName: "short",
        })}
      </Text>
    ) : null}
  </Box>
)

const HeaderTitle: React.FC<{
  state: State
}> = ({ state }) => (
  <Box
    display="flex"
    flexWrap={["wrap", "wrap", "nowrap"]}
    alignItems="center"
    pt={[5, 7]}
  >
    <Box width={[1, 1, "auto"]}>
      <Title
        color={Colors.oc.blue[9]}
        {...(state.screen === Screen.LoadingFormPreShow ||
        state.screen === Screen.Results
          ? { fontSize: [5, 8, 9] }
          : {})}
        css={{
          display: "inline",
        }}
      >
        Button Contrast Checker
      </Title>

      <Box ml={3} display="inline" css={{ verticalAlign: "super" }}>
        <Pill
          type={PillType.MAIN}
          size={
            state.screen === Screen.LoadingFormPreShow ||
            state.screen === Screen.Results
              ? PillSize.SMALL
              : PillSize.MEDIUM
          }
        >
          Free
        </Pill>
      </Box>
    </Box>

    {hasAudit(state) ? (
      <Box ml="auto" display={["none", "none", "block"]}>
        <ButtonShare />
      </Box>
    ) : null}
  </Box>
)

const HeaderForm: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
}> = ({ state, dispatch }) => (
  <Box id="header-form">
    <FormUrl
      isLoading={state.screen === Screen.LoadingForm}
      id="hero"
      {...{ state, dispatch }}
    />

    <Box maxWidth="copy">
      <Box mt={6} />
      <Lead>
        Enter your domain and we test if your buttons have enough contrast and
        are compliant with WCAG 2.1.
      </Lead>
      <Box mt={6} />
      <List>
        <ListItem display="flex" alignItems="flex-start" mb={2}>
          <Box
            css={{ marginTop: "6px" }}
            flex="0 0 auto"
            width={24}
            height={24}
            color={Colors.tw.pink["600"]}
            mr={3}
          >
            <Icons.SvgCheckCircle />
          </Box>
          <Lead>
            We test <em>default</em>, <em>hover</em> and <em>focus</em>.
          </Lead>
        </ListItem>
        <ListItem display="flex" alignItems="flex-start" mb={2}>
          <Box
            css={{ marginTop: "6px" }}
            flex="0 0 auto"
            width={24}
            height={24}
            color={Colors.tw.pink["600"]}
            mr={3}
          >
            <Icons.SvgCheckCircle />
          </Box>
          <Lead>
            Button background <em>vs.</em> Adjacent background.
          </Lead>
        </ListItem>

        <ListItem display="flex" alignItems="flex-start">
          <Box
            css={{ marginTop: "6px" }}
            flex="0 0 auto"
            width={24}
            height={24}
            color={Colors.tw.pink["600"]}
            mr={3}
          >
            <Icons.SvgCheckCircle />
          </Box>
          <Lead>Save and share results with your team.</Lead>
        </ListItem>
      </List>

      <Box mt={6} />
      <Box display="flex" alignItems="center">
        <Box
          width={42}
          height={42}
          css={{ svg: { width: "100%", height: "auto" } }}
          color={Colors.oc.blue[9]}
        >
          <Icons.SvgLoveIt />
        </Box>
        <Box flex="1 1 auto" ml={3}>
          <Label>See Demo</Label>
          <Box fontSize={4}>
            <LinkAnchor
              href="https://www.aditus.io/button-contrast-checker/getbootstrap-com-2019-09-12-at-15-02-11-139"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bootstrap Results
            </LinkAnchor>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
)

const Progress = styled.progress({
  appearance: "none",
  width: "100%",
  maxWidth: 130,
  height: 20,
  border: `1px solid ${Colors.oc.gray[6]}`,
  background: Colors.oc.white,
  color: Colors.oc.blue[9],
  borderRadius: 10,
  overflow: "hidden",
  "&::-webkit-progress-bar": {
    background: Colors.oc.white,
    border: 0,
    // borderRadius: 4
  },
  "&::-webkit-progress-value": {
    background: Colors.oc.gray[4],
    border: 0,
    // borderRadius: 4
  },
  "&::-moz-progress-bar": {
    background: Colors.oc.gray[4],
    border: 0,
  },
})

const HeaderMetadata: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
  progress: number
}> = ({ state, dispatch, progress }) => (
  <>
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="flex-start"
      id="header-metadata"
    >
      {state.screen === Screen.LoadingFormPreShow ||
      state.screen === Screen.LoadingSlug ||
      (state.screen === Screen.Results && state.audit.Result.screenshotUrl) ? (
        <Box
          display={["none", "flex"]}
          width={[202 * 0.6, 202]}
          height={[107 * 0.6, 107]}
          mr={5}
          mb={5}
          bg={Colors.oc.gray[2]}
          justifyContent="center"
          alignItems="center"
          css={{
            overflow: "hidden",
            img: {
              maxWidth: "100%",
              height: "auto",
              display: "block",
            },
          }}
          border={`1px solid ${Colors.oc.gray[3]}`}
          borderRadius={10}
        >
          {state.screen === Screen.Results ? (
            <img
              src={state.audit.Result.screenshotUrl}
              alt={`Screenshot of ${state.audit.Url} at ${new Date(
                state.audit.AuditedAt
              ).toLocaleDateString("en")}`}
            />
          ) : (
            <LoadingDots />
          )}
        </Box>
      ) : null}

      <Box display="flex" flexDirection="column">
        <LabelIconUrl
          url={
            state.screen === Screen.Results
              ? state.audit.Url
              : state.screen === Screen.LoadingFormPreShow
              ? (state as StateLoadingForm).url
              : "" // loading from slug
          }
        />
        <Box mt={[1, 2]} />
        <LabelIconDate
          date={
            state.screen === Screen.Results
              ? new Date(state.audit.AuditedAt)
              : state.screen === Screen.LoadingFormPreShow
              ? (state as StateLoadingForm).date
              : undefined // loading from slug
          }
        />

        <Box mt={[0, 5]} mb="auto" />
        {state.screen === Screen.Results ? (
          <Box display={["none", "none", "block"]} fontSize={[3]}>
            <LinkButton
              onClick={() => {
                dispatch({
                  type: ActionType.GoToForm,
                })
              }}
            >
              Check another webpage
            </LinkButton>
            <Box mt={1} />
          </Box>
        ) : state.screen === Screen.LoadingFormPreShow ? (
          <Text
            as="label"
            htmlFor="progress"
            css={{ display: "flex", alignItems: "center" }}
          >
            <Text as="span" textAlign="center">
              Scanning...
            </Text>
            <Box as="span" mr={2} />

            <Progress id="progress" value={progress} max="100" />
          </Text>
        ) : null}
      </Box>
    </Box>
  </>
)

const HeaderIllustration: React.FC<{}> = () => (
  <Box ml={[0, 0, 6]} mt={[3, 3, 0]}>
    <Box
      maxWidth={300}
      css={{ svg: { width: "100%", height: "auto" } }}
      alignSelf={["center", "center", "flex-end", "flex-start"]}
      display={["none", "none", "block"]}
    >
      <IllustrationScanColors aria-hidden="true" />
    </Box>
    <Box mt={2} />
    <Box maxWidth={["auto", "auto", 250]}>
      <LinkAnchor
        target="_blank"
        rel="noopener noreferrer"
        href="/contrast-and-accessibility/"
      >
        Learn more about contrast and accessibility
      </LinkAnchor>
    </Box>
  </Box>
)

const AppError: React.FC<{
  state: State
  dispatch: React.Dispatch<Action>
}> = ({ state, dispatch }) => (
  <Container maxWidth="l" py={8}>
    <SectionMessageWithGif
      title="Oups!! This is awkward."
      text={() => (
        <Box maxWidth="50ch">
          <Text>
            {state.screen === Screen.ErrorForm ? (
              state.errorStatus === 422 ? (
                <>
                  It looks like <em>{state.url}</em> is not a valid url or it
                  doesn't exist.
                </>
              ) : (
                <>
                  Something went wrong while analyzing the contrast of{" "}
                  <em>{state.url}</em>.
                </>
              )
            ) : null}
            {state.screen === Screen.ErrorSlug ? (
              state.errorStatus === 404 ? (
                <>This url does not exist. Maybe you copied it wrong?</>
              ) : (
                <>There was something wrong while retrieving this result.</>
              )
            ) : null}
          </Text>
          <Box mt={6} />
          <Button
            type="button"
            theme={ButtonTheme.MAIN}
            onClick={() => dispatch({ type: ActionType.GoToForm })}
          >
            Try again
          </Button>
        </Box>
      )}
      icon="SvgVideoGamePacmanEnemy"
      iconStyle={{
        path: {
          strokeWidth: 1.5,
          stroke: Colors.oc.gray[9],
        },
        ellipse: {
          stroke: Colors.oc.gray[9],
          fill: Colors.oc.white,
        },
        "path:first-of-type": {
          stroke: Colors.oc.gray[9],
          fill: Colors.tw.red["600"],
        },
      }}
      dividerProps={{
        bg: Colors.oc.gray[6],
        css: {
          filter: `drop-shadow( 0px 2px 2px ${Colors.oc.gray[5]})`,
        },
      }}
    />
  </Container>
)

const App: React.FC<MatchRenderProps<{}>> = function App(props) {
  const regexp = pathToRegexp("/button-contrast-checker/:slug")
  const routeData = regexp.exec(props.location ? props.location.pathname : "")
  const slug: string = routeData ? routeData[1] : ""
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    reducer,
    slug ? { screen: Screen.LoadingSlug, slug } : { screen: Screen.Form }
  )

  React.useEffect(() => {
    if (!slug) {
      setProgress(0)
    }
  }, [slug])

  const [progress, setProgress] = React.useState(0)
  useInterval(() => {
    if (
      state.screen === Screen.LoadingForm ||
      state.screen === Screen.LoadingFormPreShow
    ) {
      if (progress < 100) {
        setProgress(progress + 2 / 3)
      }
    }
  }, 100)

  React.useEffect(() => {
    if (!slug) {
      dispatch({ type: ActionType.GoToForm })
    }

    if (state.screen !== Screen.LoadingSlug) {
      return
    }

    async function fetchData() {
      try {
        dispatch({ type: ActionType.FetchResults, payload: { slug } })
        const response: ChecksColorsButtonsItem = await getColorsCheck({ slug })
        dispatch({
          type: ActionType.ResultsReceived,
          payload: { audit: response },
        })
      } catch (error) {
        dispatch({
          type: ActionType.ErrorSlug,
          payload: {
            status:
              error.response && error.response.status
                ? error.response.status
                : 500,
          },
        })
      }
    }
    fetchData()
  }, [slug])

  const GET_TITLE_DETAIL = url => `Button Contrast Checker - ${url} | Aditus`
  const DESCRIPTION_DETAIL = `Test all buttons and links on your webpage with just one click. Check if you have are compliant with WCAG 2.1 contrast guidelines.`
  const GET_URL_DETAIL = slug =>
    `https://www.aditus.io/button-contrast-checker/${slug}`
  const KEYWORDS_DETAIL =
    "accessibility, contrast, color, testing, a11y, audit, compliance, checker"

  return (
    <>
      {state.screen === Screen.Results ? (
        <SEO
          title={GET_TITLE_DETAIL(state.audit.Result.simpleUrl)}
          description={DESCRIPTION_DETAIL}
          keywords={KEYWORDS_DETAIL}
          jsonLd={{
            "@type": "WebPage",
            url: GET_URL_DETAIL(state.audit.Slug),
            name: GET_TITLE_DETAIL,
            description: DESCRIPTION_DETAIL,
          }}
        >
          <meta property="fb:app_id" content="824671391240758" />
          <meta property="og:url" content={GET_URL_DETAIL(state.audit.Slug)} />
          <meta
            property="og:title"
            content={GET_TITLE_DETAIL(state.audit.Result.simpleUrl)}
          />
          <meta property="og:description" content={DESCRIPTION_DETAIL} />
          <meta name="twitter:url" content={GET_URL_DETAIL(state.audit.Slug)} />
          <meta
            name="twitter:title"
            content={GET_TITLE_DETAIL(state.audit.Result.simpleUrl)}
          />
          <meta name="twitter:description" content={DESCRIPTION_DETAIL} />
        </SEO>
      ) : null}
      <Box
        bg={Colors.oc.gray[0]}
        borderBottom={
          state.screen === Screen.ErrorForm || state.screen === Screen.ErrorSlug
            ? `1px solid ${Colors.oc.gray[2]}`
            : "none"
        }
      >
        <Container
          pt={[8, 10]}
          pb={hasAudit(state) ? [6, 9] : [6, 8]}
          maxWidth="l"
        >
          <HeaderTitle state={state} />
          <Box mt={[4, 4, 6]} />
          <Box display="flex" flexWrap={["wrap", "wrap", "nowrap"]}>
            <Box flex="1 1 auto">
              {(state.screen === Screen.Form ||
                state.screen === Screen.LoadingForm) && (
                <HeaderForm
                  state={state}
                  dispatch={dispatch}
                  key={state.screen}
                />
              )}

              {[
                Screen.LoadingFormPreShow,
                Screen.LoadingSlug,
                Screen.Results,
              ].indexOf(state.screen) !== -1 ? (
                <HeaderMetadata
                  state={state}
                  dispatch={dispatch}
                  progress={progress}
                  key={state.screen}
                />
              ) : null}
            </Box>
            {(state.screen === Screen.Form ||
              state.screen === Screen.LoadingForm) && <HeaderIllustration />}
          </Box>

          {/* MOBILE ACTIONS */}
          {hasAudit(state) ? (
            <Box
              mt={-4}
              fontSize={[1, 1, 2]}
              display={["flex", "flex", "none"]}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <LinkButton
                onClick={() => {
                  dispatch({
                    type: ActionType.GoToForm,
                  })
                }}
              >
                Check another webpage
              </LinkButton>
              <ButtonShare />
            </Box>
          ) : null}
        </Container>
      </Box>
      {state.screen === Screen.Form || state.screen === Screen.LoadingForm ? (
        <Features />
      ) : null}
      {[Screen.LoadingFormPreShow, Screen.LoadingSlug, Screen.Results].indexOf(
        state.screen
      ) !== -1 ? (
        <section aria-labelledby="results">
          <h2 id="results" className="visually-hidden">
            Results
          </h2>
          <AuditResults {...{ state, dispatch }} routeProps={props} />
        </section>
      ) : null}
      {state.screen === Screen.ErrorForm ||
      state.screen === Screen.ErrorSlug ? (
        <AppError state={state} dispatch={dispatch} />
      ) : null}
      {state.screen === Screen.Form || state.screen === Screen.LoadingForm ? (
        <SectionCommunity hideLastestContent hideMission />
      ) : null}
    </>
  )
}

export default () => {
  const TITLE = "Button Contrast Checker | Aditus | Free tool"
  const DESCRIPTION = `Test all buttons and links on your webpage with just one click. Check if you have are compliant with WCAG 2.1 contrast guidelines.`
  const KEYWORDS =
    "accessibility, contrast, color, testing, a11y, audit, compliance, checker"
  const URL = "https://www.aditus.io/button-contrast-checker/"
  const OG_IMAGE = "https://www.aditus.io/social/button-contrast-checker.png"
  const OG_IMAGE_ALT =
    "Text reading 'Button Contrast Checker' with the Aditus logo next to it."
  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}
        keywords={KEYWORDS}
        jsonLd={{
          "@type": "WebPage",
          url: URL,
          name: TITLE,
          description: DESCRIPTION,
        }}
      >
        <meta property="fb:app_id" content="824671391240758" />
        <meta property="og:url" content={URL} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={TITLE} />
        <meta property="og:image" content={OG_IMAGE} />
        <meta property="og:image:alt" content={OG_IMAGE_ALT} />
        <meta property="og:description" content={DESCRIPTION} />
        <meta property="og:site_name" content="Aditus" />
        <meta property="og:locale" content="en_US" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@aditus_a11y" />
        <meta name="twitter:url" content={URL} />
        <meta name="twitter:title" content={TITLE} />
        <meta name="twitter:description" content={DESCRIPTION} />
        <meta name="twitter:image" content={OG_IMAGE} />
        <meta name="twitter:image:alt" content={OG_IMAGE_ALT} />
      </SEO>
      <Header />
      <main>
        <article>
          <ErrorBoundary>
            <Location>
              {({ location }) => (
                <Router location={location} className="router">
                  <App path="/*" />
                </Router>
              )}
            </Location>
          </ErrorBoundary>
        </article>
      </main>
      <Footer />
    </>
  )
}
