import * as React from "react"
import * as deepMerge from "deepmerge"
import { ButtonState } from "../../../backend/functions/contrast-check/interfaces"
import { Box, Label, Text } from "../../elements"
import {
  IconTextBackgroundColor,
  IconObjectBackgroundColor,
  InfoTextContrast,
} from "../../components"
import { Colors, Icons } from "../../materials"
import { CHECKER_BG_PROPS } from "./checker-background"
export const Hover: React.FC<{
  normal: ButtonState
  hover?: ButtonState
  tagName: string
  showCheckerBackground: boolean
  hoverAndNormalAreTheSame: boolean
}> = ({
  normal,
  hover,
  tagName,
  showCheckerBackground,
  hoverAndNormalAreTheSame,
}) => (
  <>
    <Box display="flex" justifyContent="center">
      <Label
        as="span"
        textAlign="center"
        css={{ display: "block", position: "relative" }}
      >
        Hover
        {hover &&
          hoverAndNormalAreTheSame &&
          hover.data.wrapperBgStyle === normal.data.wrapperBgStyle && (
            <Label
              as="sup"
              color={Colors.oc.blue[9]}
              css={{ position: "absolute", top: -4, left: "100%" }}
            >
              *
            </Label>
          )}
      </Label>
    </Box>

    <Box mb={3} />
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={2}
      py={6}
      borderLeft={[
        (hover || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none",
        (hover || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none",
        "none",
      ]}
      borderRight={[
        (hover || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none",
        (hover || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none",
        "none",
      ]}
      borderTop={
        (hover || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      borderBottom={
        (hover || normal).data.wrapperBgStyle.indexOf("rgb(255, 255, 255)") !==
        -1
          ? `1px dashed ${Colors.oc.gray[4]}`
          : "none"
      }
      css={{
        ...(showCheckerBackground
          ? (normal || hover).data.wrapperHasBgImage
            ? { background: (normal || hover).data.wrapperBgStyle }
            : CHECKER_BG_PROPS
          : {
              background: (normal || hover).data.wrapperHasBgImage
                ? (hover || normal).data.wrapperBgStyle
                : (hover || normal).data.extrapolatedColors.wrapperBackgrounds
                    .length === 1
                ? (hover || normal).data.extrapolatedColors
                    .wrapperBackgrounds[0]
                : (hover || normal).data.wrapperBgStyle,
            }),
        ...((hover || normal).data.wrapperBgStyle.indexOf(
          "rgb(255, 255, 255)"
        ) !== -1
          ? { border: `1px dashed ${Colors.oc.gray[4]}` }
          : {}),
        overflow: "hidden",
      }}
    >
      <Box
        as={tagName}
        onClick={event => event.preventDefault()}
        alignSelf="center"
        css={{
          ...deepMerge(
            {
              ...normal.data.style.computedStyle,
            },
            hover &&
              hover.data &&
              hover.data.style &&
              hover.data.style.computedStyle
              ? hover.data.style.computedStyle
              : hoverAndNormalAreTheSame
              ? {}
              : {
                  opacity: 0,
                }
          ),
        }}
        dangerouslySetInnerHTML={{ __html: normal.data.html }}
      />
    </Box>
    {hover ? (
      <>
        {/* <pre>{JSON.stringify(hover.data.style, null, 2)}</pre> */}
        <Box
          mt={4}
          display="flex"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="center"
          px={[3, 6]}
        >
          {hover.contrast.outer ? (
            <>
              {hover.contrast.inner.map((inner, index) => (
                <Box p={4} key={index}>
                  <IconTextBackgroundColor
                    color={inner.color2}
                    backgroundColor={inner.color1}
                    contrastScore={inner}
                  />
                </Box>
              ))}

              {hover.contrast.outer.map((outer, index) => (
                <Box p={4} key={index}>
                  <IconObjectBackgroundColor
                    objectBackgroundColor={outer.color2}
                    containerBackgroundColor={outer.color1}
                    contrastScore={outer}
                  />
                </Box>
              ))}
            </>
          ) : (
            hover.contrast.inner.map((inner, index) => (
              <Box p={4} key={index}>
                <IconTextBackgroundColor
                  color={inner.color2}
                  backgroundColor={inner.color1}
                  contrastScore={inner}
                />
              </Box>
            ))
          )}
        </Box>
        <Box display="flex" justifyContent="center">
          <InfoTextContrast
            contrast={hover.contrast}
            showWarning={false}
            state="HOVER"
            extraInfo={
              hover &&
              hoverAndNormalAreTheSame &&
              hover.data.wrapperBgStyle === normal.data.wrapperBgStyle
                ? () => (
                    <>
                      Same as <em>default</em> state.
                    </>
                  )
                : undefined
            }
            buttonData={{
              normal,
              hover,
              tagName,
              showCheckerBackground,
            }}
          />
        </Box>
      </>
    ) : (
      <Box
        mt={4}
        px={4}
        color={Colors.tw.red["700"]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          aria-hidden="true"
          mr={4}
          width={35}
          height={35}
          css={{
            svg: { width: "100%", height: "auto", path: { strokeWidth: 1 } },
          }}
        >
          <Icons.SvgWarning />
        </Box>
        <Text fontSize={2} css={{ maxWidth: "30ch" }}>
          There was an error while analyzing the <em>hover</em> state.
        </Text>
      </Box>
    )}
    {hover &&
      hoverAndNormalAreTheSame &&
      hover.data.wrapperBgStyle === normal.data.wrapperBgStyle && (
        <Box
          mx="auto"
          mt={[4]}
          color={Colors.oc.blue[9]}
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Text fontSize={2} css={{ maxWidth: "30ch" }}>
            <sup>*</sup>
            Same as <em>default</em> state.
          </Text>
        </Box>
      )}
  </>
)
