import * as React from "react"

import { State, Action, ActionType, Screen } from "./interfaces"
import { Formik, FormikProps, FormikErrors } from "formik"
import { ChecksColorsButtonsItem } from "../../../backend/functions/contrast-check/interfaces"
import { getColorsCheck } from "../../utils/api/get-colors-check"
import { validateUrl } from "../../utils/api/validate-url"
import { trackFormSubmitEvent } from "../../utils/track-ga-event"
import {
  Box,
  FormSingleInput as Form,
  FormSingleInputTheme as FormTheme,
  Button,
  Text,
  Input,
  ButtonTheme,
  ButtonIcon,
} from "../../elements"
import { navigate } from "@reach/router"
import { Icons, Colors } from "../../materials"
import debounce from "lodash/debounce"

interface Props {
  url: string
}

const ESC = 27

export const FormUrl: React.FC<{
  id: string
  isLoading: boolean
  dispatch: React.Dispatch<Action>
}> = function({ id, isLoading = false, dispatch }) {
  const inputRef = React.useRef(null)
  const submitRef = React.useRef(null)
  const [isValidating, setIsValidating] = React.useState<boolean>(false)
  const [internalIsValid, setInternalIsValid] = React.useState<boolean>(false)

  const debouncedValidation = debounce(
    async (value: string, formikProps?: FormikProps<Props>) => {
      if (isLoading) {
        return
      }
      if (!value) {
        formikProps && formikProps.setFieldError("url", "Can't be empty.")
        setInternalIsValid(false)
      } else {
        try {
          const isValid = (await validateUrl(value)).isValid
          if (isValid) {
            // @ts-ignore
            formikProps && formikProps.setFieldError("url", undefined)
            setInternalIsValid(true)
          } else {
            formikProps &&
              formikProps.setFieldError("url", "Needs to be a valid url.")
            setInternalIsValid(false)
          }
        } catch (e) {
          formikProps &&
            formikProps.setFieldError("url", "Needs to be a valid url.")
          setInternalIsValid(false)
        }
      }

      setIsValidating(false)
    },
    1000,
    { maxWait: 1000 }
  )

  const customOnBlur = (
    event: React.ChangeEvent<any>,
    formikProps: FormikProps<Props>
  ) => {
    setIsValidating(true)
    formikProps.handleBlur(event)
    debouncedValidation(event.target.value, formikProps)
  }

  const customOnChange = (
    event: React.ChangeEvent<any>,
    formikProps: FormikProps<Props>
  ) => {
    formikProps.handleChange(event)
    debouncedValidation(event.target.value)
  }

  return (
    <Formik<Props>
      initialValues={{ url: "" }}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={async (values, _formikActions) => {
        try {
          dispatch({ type: ActionType.GenerateResults, payload: values })
          setTimeout(() => {
            dispatch({
              type: ActionType.ShowPreResults,
            })
          }, 500)
          trackFormSubmitEvent("button-contrast-checker")
          const response: ChecksColorsButtonsItem = await getColorsCheck({
            url: values.url,
          })
          dispatch({
            type: ActionType.ResultsReceived,
            payload: { audit: response },
          })
          const slug = response.Slug
          navigate(`/button-contrast-checker/${slug}`)
        } catch (error) {
          dispatch({
            type: ActionType.ErrorForm,
            payload: {
              status:
                error.response && error.response.status
                  ? error.response.status
                  : 500,
            },
          })
        }
      }}
    >
      {formikProps => {
        const {
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isValid,
          /* and other goodies */
        } = formikProps
        return (
          <>
            <Form
              onSubmit={handleSubmit}
              minWidth={[1, 1, 600]}
              width={[1, 1, "auto"]}
              display="flex"
              flexDirection={["column", "row"]}
              css={{ position: "relative" }}
              theme={FormTheme.MAIN}
              hasError={errors && errors.url && touched && touched.url}
              disabled={isLoading}
              novalidate
            >
              <Box
                className="input-wrapper"
                display="flex"
                css={{ position: "relative" }}
              >
                <Input
                  ref={inputRef}
                  id={`${id}-url`}
                  name="url"
                  inputmode="url"
                  value={values.url}
                  aria-label="Website address"
                  placeholder="Enter URL"
                  onChange={event => customOnChange(event, formikProps)}
                  onBlur={event => customOnBlur(event, formikProps)}
                  aria-describedby={`${id}-error-url`}
                  disabled={isLoading}
                  onKeyDown={event => {
                    if (event.keyCode === ESC) {
                      resetForm()
                    }
                  }}
                />
                <Box
                  alignItems="center"
                  justifyContent="center"
                  px={1}
                  className="show-on-focus"
                >
                  <ButtonIcon
                    type="button"
                    aria-label="Clear url"
                    css={{
                      display: values.url.length === 0 ? "none" : "flex",
                    }}
                    onClick={() => {
                      resetForm()
                      inputRef.current && inputRef.current.focus()
                    }}
                  >
                    <Icons.SvgClose />
                  </ButtonIcon>
                </Box>
              </Box>
              {/* SPACE on mobile */}
              <Box mt={1} display={["block", "none"]} />
              <Box
                css={{
                  position: "absolute",
                  right: 0,
                  bottom: "100%",
                }}
                display="flex"
                alignItems="center"
                color={Colors.oc.red[5]}
              >
                <Text fontSize={1} id={`${id}-error-url`}>
                  {errors && errors.url && touched && touched.url
                    ? errors.url
                    : ""}
                </Text>
                {errors &&
                errors.url &&
                touched &&
                touched.url &&
                !isValidating ? (
                  <Box width={14} ml={2}>
                    <Icons.SvgWarning />
                  </Box>
                ) : null}
              </Box>

              <Button
                theme={ButtonTheme.MAIN}
                disabled={isLoading}
                type="submit"
                ref={submitRef}
                css={{
                  ...(isLoading
                    ? {
                        paddingLeft: "50px",
                        paddingRight: "50px",
                      }
                    : {}),
                  whiteSpace: "nowrap",
                }}
              >
                {isLoading ? "Scanning..." : "Scan your webpage"}
              </Button>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}
